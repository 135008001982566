import { render, staticRenderFns } from "./InstantSearchAutocomplete.vue?vue&type=template&id=5e773c18"
import script from "./InstantSearchAutocomplete.vue?vue&type=script&lang=ts"
export * from "./InstantSearchAutocomplete.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputSearch: require('/home/runner/work/webapp/webapp/components/molecules/form/input/InputSearch.vue').default,InstantSearchAutocompleteSuggestions: require('/home/runner/work/webapp/webapp/components/molecules/instantsearch/InstantSearchAutocompleteSuggestions.vue').default})
