import { render, staticRenderFns } from "./CardProduct.vue?vue&type=template&id=65c97e8f"
import script from "./CardProduct.vue?vue&type=script&lang=ts"
export * from "./CardProduct.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BadgeDiscount: require('/home/runner/work/webapp/webapp/components/atoms/badge/BadgeDiscount.vue').default,BadgeSoldOut: require('/home/runner/work/webapp/webapp/components/atoms/badge/BadgeSoldOut.vue').default,ButtonAddToSelectionTriage: require('/home/runner/work/webapp/webapp/components/molecules/button/ButtonAddToSelectionTriage.vue').default,ProductWishlist: require('/home/runner/work/webapp/webapp/components/molecules/product/ProductWishlist.vue').default,BaseLink: require('/home/runner/work/webapp/webapp/components/atoms/link/BaseLink.vue').default,ProductRating: require('/home/runner/work/webapp/webapp/components/molecules/product/ProductRating.vue').default,ProductPrice: require('/home/runner/work/webapp/webapp/components/molecules/product/ProductPrice.vue').default})
