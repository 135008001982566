
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

import { EHttpStatusCode } from '@/domain/core/http/HttpStatusCode.enum';
import { HttpResponse } from '@/infrastructure/core/http/HttpResponse.interface';
import { _isEmailValid } from '@/utilities/email/isEmailValid';

const successfulSubscriptionStatus = 'subscribed';
const messageDisplayTimeInMs = 4000;

export default Vue.extend({
  name: 'FormNewsletter',
  data() {
    return {
      isLoading: false,
      subscriptionEmail: '',
      errorMessage: null as TranslateResult | null,
      shouldDisplaySuccessMessage: false,
    };
  },
  methods: {
    handleSubmit(): void {
      if (!_isEmailValid(this.subscriptionEmail)) {
        this.displayErrorMessage(this.$t('form.subscription.incorrectEmailMessage'));

        return;
      }

      this.subscribeToNewsletter();
    },
    async subscribeToNewsletter(): Promise<void> {
      try {
        this.isLoading = true;

        const recaptchaToken = await this.$recaptcha.execute('newsletter');
        const content = await this.$repositories.auth.subscribeNewsletter(this.subscriptionEmail, recaptchaToken);

        if (content?.status !== successfulSubscriptionStatus) {
          throw new Error('Unsuccessful subscription');
        }

        this.$emit('subscribe');
        this.displaySuccessMessage();
      } catch (err) {
        const { content, statusCode } = err as HttpResponse;
        let errorMessage = this.$t('form.common.errors.generic');

        if (statusCode === EHttpStatusCode.InternalServerError) {
          this.$errorMonitor.report(err, 'fatal');
        }

        const errors = content?.errors;

        if (errors?.length) {
          const userAlreadySubscribedErrorKey = 'validation.user.newsletter_subscription.not_exists';

          for (const error of errors) {
            if (error?.message?.includes(userAlreadySubscribedErrorKey)) {
              errorMessage = this.$t('footer.newsletter.error.alreadySubscribed');
            }

            this.displayErrorMessage(errorMessage);
          }
        } else {
          this.displayErrorMessage(errorMessage);
        }
      } finally {
        this.isLoading = false;
      }
    },
    displayErrorMessage(message: TranslateResult): void {
      this.shouldDisplaySuccessMessage = false;
      this.errorMessage = message;
    },
    displaySuccessMessage(): void {
      this.errorMessage = null;

      this.shouldDisplaySuccessMessage = true;
      setTimeout(() => {
        this.shouldDisplaySuccessMessage = false;
      }, messageDisplayTimeInMs);
    },
  },
});
