import { actionTree, getterTree, mutationTree } from 'typed-vuex';

import { EWishlist as MutationType } from '@/domain/core/store/MutationType.enum';
import { Wishlist, WishlistBoard, WishlistProduct } from '@/domain/wishlist/types';
import { WishlistState } from '@/infrastructure/core/store/modules/Wishlist.interface';
import { accessorType } from '@/store';

export const state = (): WishlistState => ({
  boards: [],
  createdAt: null,
  createdBy: {
    userId: '',
  },
  defaultBoard: false,
  id: null,
  products: [],
  updatedAt: null,
});

export const getters = getterTree(state, {
  getWishlistProductIds: ({ products, boards }): WishlistProduct['id'][] => {
    const productIds = products?.map(({ addedAt, id }) => ({ addedAt, id })) || [];
    const boardsProductIds = boards
      ?.filter(({ archived }) => !archived)
      ?.flatMap(({ products }) => products.map(({ addedAt, id }) => ({ addedAt, id }))) || [];
    const productIdsOrderedByAddedAt = [...productIds, ...boardsProductIds]
      .sort((a, b) => new Date(a.addedAt!).getTime() - new Date(b.addedAt!).getTime())
      .map(({ id }) => id);

    return [...new Set(productIdsOrderedByAddedAt)];
  },
});

export const mutations = mutationTree(state, {
  [MutationType.SET_WISHLIST]: (state, payload: Wishlist): void => {
    Object.assign(state, payload);
  },
});

export const actions = actionTree({ state, mutations }, {
  async addProduct(_, { productId, boardId }: { boardId?: WishlistBoard['id']; productId: WishlistProduct['id'] }): Promise<void> {
    const accessor: typeof accessorType = this.app.$accessor;
    let wishlistId = accessor.wishlist.id;
    const { authenticated } = accessor.user;

    if (!authenticated) {
      throw new Error('User is not authenticated');
    } else if (!productId) {
      throw new Error('Missing productId');
    } else if (!wishlistId) {
      await accessor.wishlist.createWishlist();

      wishlistId = accessor.wishlist.id;
    }

    try {
      const wishlist = await this.$repositories.wishlist.addProduct({
        boardId,
        productId,
        wishlistId,
      });

      accessor.wishlist.SET_WISHLIST(wishlist);
    } catch (err) {
      this.$errorMonitor.report(err, 'error');
    }
  },
  async createBoard(_, boardName: WishlistBoard['name']): Promise<void> {
    const accessor: typeof accessorType = this.app.$accessor;
    const wishlistId = accessor.wishlist.id;
    const { authenticated } = accessor.user;

    if (!authenticated) {
      throw new Error('User is not authenticated');
    } else if (!boardName) {
      throw new Error('Missing boardName');
    }

    try {
      const wishlist = await this.$repositories.wishlist.createBoard({
        boardName,
        wishlistId,
      });

      accessor.wishlist.SET_WISHLIST(wishlist);
    } catch (err) {
      this.$errorMonitor.report(err, 'error');
    }
  },
  async createWishlist(): Promise<void> {
    const accessor: typeof accessorType = this.app.$accessor;
    const { authenticated } = accessor.user;

    if (!authenticated) {
      throw new Error('User is not authenticated');
    }

    try {
      const wishlist = await this.$repositories.wishlist.createWishlist();

      accessor.wishlist.SET_WISHLIST(wishlist);
    } catch (err) {
      this.$errorMonitor.report(err, 'error');
    }
  },
  async fetchWishlist(): Promise<void> {
    const accessor: typeof accessorType = this.app.$accessor;
    const { token, legacyId: legacyUserId } = accessor.user;

    if (!token || !legacyUserId) {
      throw new Error('User token or ID missing');
    }

    try {
      const wishlist = await this.$repositories.wishlist.getWishlistByUserId(legacyUserId);

      accessor.wishlist.SET_WISHLIST(wishlist);
    } catch (err) {
      this.$errorMonitor.report(err, 'error');
    }
  },
  async removeProduct(_, productId: string): Promise<void> {
    const accessor: typeof accessorType = this.app.$accessor;
    const wishlistId = accessor.wishlist.id;
    const { authenticated } = accessor.user;

    if (!authenticated) {
      throw new Error('User is not authenticated');
    } else if (!wishlistId || !productId) {
      throw new Error('Missing wishlistId or productId');
    }

    try {
      const wishlist = await this.$repositories.wishlist.removeProduct({
        productId,
        wishlistId,
      });

      accessor.wishlist.SET_WISHLIST(wishlist);
    } catch (err) {
      this.$errorMonitor.report(err, 'error');
    }
  },
});
