import { render, staticRenderFns } from "./ProductWishlist.vue?vue&type=template&id=639191b4"
import script from "./ProductWishlist.vue?vue&type=script&lang=ts"
export * from "./ProductWishlist.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonAddToWishlist: require('/home/runner/work/webapp/webapp/components/molecules/button/ButtonAddToWishlist.vue').default,WishlistBoardsDropdownOpener: require('/home/runner/work/webapp/webapp/components/molecules/wishlist/WishlistBoardsDropdownOpener.vue').default,WishlistBoardsDropdown: require('/home/runner/work/webapp/webapp/components/molecules/wishlist/WishlistBoardsDropdown.vue').default,WishlistBoardsDropdownOpenerButton: require('/home/runner/work/webapp/webapp/components/molecules/wishlist/WishlistBoardsDropdownOpenerButton.vue').default,BaseModal: require('/home/runner/work/webapp/webapp/components/atoms/modal/BaseModal.vue').default,ModalWishlistBoard: require('/home/runner/work/webapp/webapp/components/organisms/modal/ModalWishlistBoard.vue').default})
