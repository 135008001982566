import { legacyProductAdapter } from '@/adapters/product.adapter';
import { EditoRepositoryInterface } from '@/domain/edito/editoRepository.interface';
import { EBlockType, EditoBlock } from '@/domain/edito/types';
import { ApiLegacyProduct, Product } from '@/domain/product/types';
import { _isCurrentDateBetweenTwoDates } from '@/utilities/dates/isCurrentDateBetweenTwoDates';

export class EditoBlocksDataService {
  readonly #editoRepository: EditoRepositoryInterface;

  constructor(editoRepository: EditoRepositoryInterface) {
    this.#editoRepository = editoRepository;
  }

  async getEditoBlocks(homepagePreviewId?: string): Promise<EditoBlock[]> {
    const homepageRequest = await this.#editoRepository.getHomepage(homepagePreviewId);

    const countByBlockType: Record<string, number> = {};

    const [{ body: blocks }] = homepageRequest?.listing?.elements || [{ body: [] }];
    const publishedBlocks: EditoBlock[] = blocks
      // Extract published blocks only
      .filter((block) => {
        if (block.endingAt && block.startingAt) {
          return _isCurrentDateBetweenTwoDates({ end: block.endingAt, start: block.startingAt });
        }

        return true;
      })
      // Transform legacy products into modern ones (temporary - hopefully 🤡)
      .map((block) => {
        if (block.type === EBlockType.MainBlock || block.type === EBlockType.Selection) {
          let products: Record<string, Partial<Product>> = {
            first: legacyProductAdapter(block.first as unknown as ApiLegacyProduct), // 🤡
            second: legacyProductAdapter(block.second as unknown as ApiLegacyProduct), // 🤡
            third: legacyProductAdapter(block.third as unknown as ApiLegacyProduct), // 🤡
            fourth: legacyProductAdapter(block.fourth as unknown as ApiLegacyProduct), // 🤡
          };

          if (block.type === EBlockType.Selection) {
            products = {
              ...products,
              fifth: legacyProductAdapter(block.fifth as unknown as ApiLegacyProduct), // 🤡
              sixth: legacyProductAdapter(block.sixth as unknown as ApiLegacyProduct), // 🤡
              seventh: legacyProductAdapter(block.seventh as unknown as ApiLegacyProduct), // 🤡
            };
          }

          return {
            ...block,
            ...products,
          };
        }

        return block;
      })
      // Add counter by block type for tracking purposes
      .map((block) => {
        if (countByBlockType[block.type] === undefined) {
          countByBlockType[block.type] = 1;
        } else {
          countByBlockType[block.type] += 1;
        }

        return {
          ...block,
          indexByBlockType: countByBlockType[block.type],
        };
      });

    // Insert placeholders within blocks for the following carousels
    // - "last viewed items block" at position 3
    // - "tailored items" portal (not the actual carousel).
    publishedBlocks.splice(2, 0,
      { type: EBlockType.LastViewedItems },
      { type: EBlockType.TailoredItems },
    );

    return publishedBlocks;
  }
}
