import { render, staticRenderFns } from "./ModalWishlistBoard.vue?vue&type=template&id=1c413d70"
import script from "./ModalWishlistBoard.vue?vue&type=script&lang=ts"
export * from "./ModalWishlistBoard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardProduct: require('/home/runner/work/webapp/webapp/components/organisms/card/CardProduct.vue').default,FormWishlistBoard: require('/home/runner/work/webapp/webapp/components/organisms/form/FormWishlistBoard.vue').default,BaseModal: require('/home/runner/work/webapp/webapp/components/atoms/modal/BaseModal.vue').default})
