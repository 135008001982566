
import Vue from 'vue';

enum ELinkType {
  Native = 'a',
  NuxtLink = 'NuxtLink',
}

type LinkType = `${ELinkType}`;

export default Vue.extend({
  name: 'BaseLink',
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    isUnstyled: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    linkType(): LinkType {
      if (this.to) {
        return ELinkType.NuxtLink;
      }

      return ELinkType.Native;
    },
  },
  methods: {
    handleClick(event: Event): void {
      this.$emit('click', event);
    },
    handleFocus(event: Event): void {
      this.$emit('focus', event);
    },
  },
});
