import {
  LegacyWishlist,
  LegacyWishlistBoard,
  LegacyWishlistCreatedBy,
  LegacyWishlistProduct,
  Wishlist,
  WishlistBoard,
  WishlistCreatedBy,
  WishlistProduct,
} from '@/domain/wishlist/types';

export const legacyWishlistAdapter = (wishlist: LegacyWishlist): Wishlist => ({
  id: wishlist?.id || null,
  name: wishlist?.name || null,
  products: legacyWishlistProductListAdapter(wishlist?.products),
  boards: legacyWishlistBoardListAdapter(wishlist?.boards),
  default: wishlist?.default || null,
  defaultBoard: wishlist?.default_board || false,
  createdBy: legacyWishlistCreatedByAdapter(wishlist?.created_by),
  createdAt: wishlist?.created_at || null,
  updatedAt: wishlist?.updated_at || null,
});

export const legacyWishlistCreatedByAdapter = (createdBy: LegacyWishlistCreatedBy): WishlistCreatedBy => ({
  userId: createdBy?.user_id || null,
  locale: createdBy?.locale || null,
});

export const legacyWishlistProductAdapter = (product: LegacyWishlistProduct): WishlistProduct => ({
  addedAt: product?.added_at || null,
  id: product?.id || null,
  imageId: product?.image_id || null,
});

export const legacyWishlistProductListAdapter = (products: LegacyWishlistProduct[]): WishlistProduct[] => products
  ?.map((product) => legacyWishlistProductAdapter(product)) || [];

export const legacyWishlistBoardAdapter = (board: LegacyWishlistBoard): WishlistBoard => ({
  archived: board?.archived || null,
  archivedAt: board?.archived_at || null,
  configuration: board?.configuration || null,
  default: board?.default || false,
  id: board?.id || null,
  name: board?.name || null,
  products: legacyWishlistProductListAdapter(board?.products),
});

export const legacyWishlistBoardListAdapter = (boards: LegacyWishlistBoard[]): WishlistBoard[] => boards
  ?.map((board) => legacyWishlistBoardAdapter(board)) || [];
