// TODO: Remove in favour of the i18n router
import { Plugin } from '@nuxt/types';

import { ELanguage } from '@/domain/core/Language.enum';
import ExternalURLRouter from '@/services/externalURLRouter.service';

const externalURLRouterPlugin: Plugin = ({ req, $errorMonitor, $config }, inject) => {
  const domain = process.server ? req?.headers?.host : window.location.host;
  const domainDefaultLanguages = {
    [$config.domains.FR as string]: ELanguage.FR,
    [$config.domains.GB as string]: ELanguage.EN,
    [$config.domains.NL as string]: ELanguage.EN,
  };
  const defaultLanguage = ELanguage.FR;

  let language = domain ? domainDefaultLanguages[domain] : null;

  if (!language) {
    $errorMonitor.report(`No language defined for domain "${domain}", using "${defaultLanguage}"`, 'error');

    language = defaultLanguage;
  }

  const routes = require(`@/config/plugins/externalURLRoutes/${language}.json`);

  inject('externalURLRouter', new ExternalURLRouter(routes, $errorMonitor));
};

export default externalURLRouterPlugin;
