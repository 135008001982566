import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { ApiItemBase } from '@/domain/core/api/ApiItemBase.interface';
import { PaginatedList } from '@/domain/core/api/types';

export const transform = <TItem>(apiData: ApiItemBase<TItem, {}>): TItem => {
  const hasEmbeddedObject = apiData.embeds !== undefined && Object.keys(apiData.embeds).length > 0;

  if (!hasEmbeddedObject) {
    return apiData.item;
  }

  const transformedApiData: TItem = { ...apiData.item };

  for (const embedKey in apiData.embeds) {
    // @ts-ignore - FIXME: embeds need to be fully typed. The way payloads are typed need to be refactored beforehand.
    const embed = apiData.embeds[embedKey];
    const isEmbeddedList = Object.prototype.hasOwnProperty.call(embed, 'items');

    (transformedApiData as Record<string, unknown>)[embedKey] = isEmbeddedList
      ? transformList<TItem[]>(embed.items)
      : transform<TItem>(embed);
  }

  return transformedApiData;
};

export const transformList = <TItem>(items: ApiItemBase<TItem, {}>[]): TItem[] => {
  return items?.map((item: ApiItemBase<TItem, {}>) => transform(item)) || [];
};

export const transformListPaginated = <TItem>({ items, pagination }: ApiBase<TItem>): PaginatedList<TItem> => ({
  items: transformList(items),
  pagination,
});
