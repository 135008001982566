
import Vue, { PropType } from 'vue';

import { Product } from '@/domain/product/types';
import { EGtmViewModal } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

export default Vue.extend({
  name: 'ModalWishlistBoard',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  mounted() {
    this.$gtm.push({ event: EGtmViewModal.WishlistBoardCreation });
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
  },
});
