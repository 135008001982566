
import Vue, { PropType } from 'vue';

import { SidebarComponent, SidebarComponentId } from '@/infrastructure/core/store/modules/Ui.interface';

export default Vue.extend({
  name: 'SidebarToggle',
  props: {
    currentComponent: {
      type: Object as PropType<SidebarComponent>,
      default: (): SidebarComponent => null,
    },
    previousComponent: {
      type: Object as PropType<SidebarComponent>,
      default: (): SidebarComponent => null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentSidebarId(): SidebarComponentId | null {
      return this.currentComponent?.id || null;
    },
    isAriaExpanded(): boolean {
      return this.$accessor?.ui?.sidebar?.currentComponent?.id === this.currentSidebarId;
    },
  },
  methods: {
    handleClick(): void {
      this.$accessor.ui.openSidebar({
        currentComponent: this.currentComponent,
        previousComponent: this.previousComponent,
      });

      this.$emit('click');
    },
  },
});
