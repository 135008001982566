import { actionTree, getterTree, mutationTree } from 'typed-vuex';

import { Address } from '@/domain/address/types';
import { AllCookies } from '@/domain/core/http/Cookie.enum';
import { ELocalStorageItem } from '@/domain/core/LocalStorageItem.enum';
import { EAddress as MutationType } from '@/domain/core/store/MutationType.enum';
import { AddressState } from '@/infrastructure/core/store/modules/Address.interface';
import { accessorType } from '@/store';

export const state = (): AddressState => ({
  addresses: [],
  isFetching: false,
  selectedAddress: {
    billingId: null,
    shippingId: null,
  },
});

export const getters = getterTree(state, {
  firstAddress: ({ addresses }): Address | null => {
    const [first = null] = addresses || [];

    return first;
  },
  billing: ({ addresses, selectedAddress }): Address | null => addresses?.find(({ id }) => id === selectedAddress.billingId) || null,
  shipping: ({ addresses, selectedAddress }): Address | null => addresses?.find(({ id }) => id === selectedAddress.shippingId) || null,
});

export const mutations = mutationTree(state, {
  [MutationType.SET_ADDRESSES]: (state, payload: Address[]): void => {
    state.addresses = payload;
  },
  [MutationType.SET_BILLING_ID]: (state, id: Address['id'] | null): void => {
    state.selectedAddress.billingId = id;
  },
  [MutationType.SET_FETCH_STATE]: (state, payload: boolean): void => {
    state.isFetching = payload;
  },
  [MutationType.SET_SHIPPING_ID]: (state, id: Address['id'] | null): void => {
    state.selectedAddress.shippingId = id;
  },
});

export const actions = actionTree({ state, mutations }, {
  async fetchUserAddresses(): Promise<void> {
    const { userId: id } = this.$cookies.getAll<AllCookies>(); // Cookies are the source of truth.
    const accessor: typeof accessorType = this.app.$accessor;

    if (!id || accessor.address.isFetching) {
      return;
    }

    accessor.address.SET_FETCH_STATE(true);

    const addresses = await this.$repositories.user.getAddresses(id || '');

    accessor.address.SET_ADDRESSES(addresses);
    accessor.address.SET_FETCH_STATE(false);
  },
  // WARNING: this action can be called client side only. 🫠
  setDefaultAddressesFromLocalStorage(): void {
    const accessor: typeof accessorType = this.app.$accessor;
    const firstAddressId = accessor.address.firstAddress?.id || null;

    const isAddressIdInList = (addressId: string | null): boolean => accessor.address.addresses?.some(({ id }) => id === addressId);

    // NOTE: though the two following functions could be refactored into one, they are more readable as is.
    const setShippingId = (shippingId: string | null): void => {
      if (shippingId && isAddressIdInList(shippingId)) {
        accessor.address.setShippingId(shippingId);
      }
    };

    const setBillingId = (billingId: string | null): void => {
      if (billingId && isAddressIdInList(billingId)) {
        accessor.address.setBillingId(billingId);
      }
    };

    const shippingId = localStorage?.getItem(ELocalStorageItem.DefaultShippingAddressId);
    const billingId = localStorage?.getItem(ELocalStorageItem.DefaultBillingAddressId);

    let selectedShippingId = shippingId;
    let selectedBillingId = billingId;

    if (!isAddressIdInList(selectedShippingId)) {
      selectedShippingId = firstAddressId;
      localStorage.removeItem(ELocalStorageItem.DefaultShippingAddressId);
    }

    if (!isAddressIdInList(selectedBillingId)) {
      selectedBillingId = selectedShippingId || firstAddressId;
      localStorage.removeItem(ELocalStorageItem.DefaultBillingAddressId);
    }

    setShippingId(selectedShippingId);
    setBillingId(selectedBillingId);
  },
  setBillingId(_, id: string): void {
    const accessor: typeof accessorType = this.app.$accessor;

    localStorage?.setItem(ELocalStorageItem.DefaultBillingAddressId, id);
    accessor.address.SET_BILLING_ID(id);
  },
  setShippingId(_, id: string): void {
    const accessor: typeof accessorType = this.app.$accessor;

    localStorage?.setItem(ELocalStorageItem.DefaultShippingAddressId, id);
    accessor.address.SET_SHIPPING_ID(id);
  },
});
