
import Vue from 'vue';

import { SearchQuery } from '@/domain/cms/types';
import { ERouteName } from '@/domain/core/Routes.enum';
import { EGtmEventSearch } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { SearchEvent } from '@/infrastructure/externalServices/gtm/search/SearchEventPayload.interface';

export default Vue.extend({
  name: 'SearchListPopular',
  data() {
    return {
      maxSearchQueriesPlaceholders: 6,
      popularSearches: [] as SearchQuery[],
    };
  },
  async fetch(): Promise<void> {
    try {
      this.popularSearches = await this.$repositories.cms.getPopularSearchQueries();
    } catch (err) {
      this.$errorMonitor.report(err, 'error');

      throw new Error(JSON.stringify(err));
    }
  },
  fetchOnServer: false,
  methods: {
    getSearchQueryHref(query: string): string {
      return this.$router.resolve({
        name: ERouteName.Search,
        query: { query },
      }).href;
    },
    handleClick(query: string): void {
      this.$emit('click-suggestion', query);
      this.handleTracking(query);
    },
    handleTracking(query: string): void {
      const payload: SearchEvent = {
        search_term: query,
        url: `${window.location.origin}${this.getSearchQueryHref(query)}`,
      };

      this.$gtm.push({
        event: EGtmEventSearch.ClickPopular,
        ...payload,
      });
    },
  },
});
