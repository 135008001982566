
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

import { Link } from '@/infrastructure/core/components/link/Link.interface';
import { EGtmEventUser, ENewsletterSubmitLocation } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

interface ReinsuranceLink extends Link {
  message: TranslateResult;
}

export default Vue.extend({
  name: 'FooterMain',
  computed: {
    reinsuranceItems(): ReinsuranceLink[] {
      const baseI18nPath = 'footer.reinsurance';

      return [
        {
          label: this.$t(`${baseI18nPath}.trust.label`),
          message: this.$t(`${baseI18nPath}.trust.message`),
          props: { href: this.$externalURLRouter.getPathFromRoute('warranty-authentication') },
        },
        {
          label: this.$t(`${baseI18nPath}.secure.label`),
          message: this.$t(`${baseI18nPath}.secure.message`),
          props: { href: this.$externalURLRouter.getPathFromRoute('warranty-paiements') },
        },
        {
          label: this.$t(`${baseI18nPath}.warranty.label`),
          message: this.$t(`${baseI18nPath}.warranty.message`),
          props: { href: this.$externalURLRouter.getPathFromRoute('warranty-warranty') },
        },
      ];
    },
  },
  methods: {
    handleTracking(): void {
      this.$gtm.push({
        event: EGtmEventUser.NewsletterOptin,
        location: ENewsletterSubmitLocation.Footer,
      });
    },
  },
});
