// TODO: remove this repository and use a notification repository

import { ApiLegacyBase } from '@/domain/core/api/ApiLegacyBase.interface';
import { EHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import { ForumRepositoryInterface } from '@/domain/forum/forumRepository.interface';
import { EMessageType, LegacyMessage, LegacyMessagePayload, LegacyOfferPayload } from '@/domain/forum/types';
import HttpService from '@/services/http.service';
import { _generateRandomString } from '@/utilities/misc/idGenerator';

export default class ForumRepository implements ForumRepositoryInterface {
  readonly #httpService: HttpService;
  readonly #basePath: string;
  readonly #security: SecurityTokenProvider;

  constructor(httpService: HttpService, basePath: string, security: SecurityTokenProvider) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#security = security;
  }

  async getUnreadPrivateMessagesCount(): Promise<number> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<number>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}/threads/count-unread`,
    });

    return content?.content;
  }

  async sendMessage(payload: LegacyMessagePayload): Promise<LegacyMessage> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyMessage>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Post,
      path: `${this.#basePath}/messages`,
      payload: {
        ...payload,
        type: EMessageType.Message,
        uuid: _generateRandomString(), // TODO: remove once id generation is back-end side
      },
    });

    return content?.content;
  }

  async sendOffer(payload: LegacyOfferPayload): Promise<LegacyMessage> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyMessage>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Post,
      path: `${this.#basePath}/messages`,
      payload: {
        ...payload,
        type: EMessageType.Offer,
        uuid: _generateRandomString(), // TODO: remove once id generation is back-end side
      },
    });

    return content?.content;
  }
}
