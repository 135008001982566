import { ECountryCodeISO31661 } from './Country.enum';

export enum ELanguage {
  EN = 'en',
  FR = 'fr',
}

export enum ELanguageName {
  EN = 'English',
  FR = 'Français',
}

export const LanguageISO = {
  EN: {
    GB: `${ELanguage.EN}-${ECountryCodeISO31661.GB}`,
    NL: `${ELanguage.EN}-${ECountryCodeISO31661.NL}`,
    US: `${ELanguage.EN}-${ECountryCodeISO31661.US}`,
  },
  FR: {
    FR: `${ELanguage.FR}-${ECountryCodeISO31661.FR}`,
  },
} as const;
