
import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';

import IcHeart from '@/assets/svg/ic_heart.svg?inline';
import { ESidebarComponentId } from '@/domain/core/a11y/SidebarComponentId.enum';
import { Product } from '@/domain/product/types';
import { SidebarComponent } from '@/infrastructure/core/store/modules/Ui.interface';

interface LocalComponentType {
  name: string;
  props: Record<string, any>;
}

export default Vue.extend({
  name: 'ButtonAddToWishlist',
  components: {
    IcHeart,
  },
  props: {
    productId: {
      type: String as PropType<Product['id'] | Product['legacyId']>,
      required: true,
    },
    productSku: {
      type: String as PropType<Product['sku']>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isProductInWishlist: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    FormLogin(): SidebarComponent {
      const redirectPath = this.$externalURLRouter.getPathFromRoute('add-to-wishlist', { sku: this.productSku });
      const queryParams = new URLSearchParams({ _target_path: this.$route.fullPath });

      return {
        // @ts-ignore
        component: () => import('@/components/organisms/form/FormLogin.vue'),
        id: ESidebarComponentId.FormLogin,
        props: {
          targetPath: `${redirectPath}?${queryParams.toString()}`,
        },
      };
    },
    buttonTitle(): TranslateResult {
      const i18nPath = 'product.wishlist.button.title';

      return this.isProductInWishlist
        ? this.$t(`${i18nPath}.removeProduct`)
        : this.$t(`${i18nPath}.addProduct`);
    },
    componentType(): LocalComponentType {
      let component: LocalComponentType = {
        name: 'SidebarToggle',
        props: {
          currentComponent: this.FormLogin,
          title: this.buttonTitle,
        },
      };

      if (this.isAuthenticated) {
        component = {
          name: 'BaseButton',
          props: {
            isUnstyled: true,
            isDisabled: this.isLoading,
            title: this.buttonTitle,
          },
        };
      }

      return component;
    },
    isAuthenticated(): boolean {
      return this.$accessor.user.authenticated;
    },
  },
  methods: {
    handleClick(): void {
      this.$emit('click');
    },
  },
});
