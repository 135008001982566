
import Vue, { PropType } from 'vue';

import { ENumberFormatPart } from '@/domain/core/NumberFormatPart.enum';
import { ESchema, ESchemaOffer, ESchemaProduct } from '@/domain/core/Schema.enum';

export default Vue.extend({
  name: 'MicrodataOffer',
  props: {
    isAvailable: {
      type: Boolean,
      required: false,
    },
    priceInParts: {
      type: Array as PropType<Intl.NumberFormatPart[]>,
      required: true,
    },
    selectedCurrencyCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ESchema,
      ESchemaProduct,
      ESchemaOffer,
    };
  },
  computed: {
    availability(): string {
      return this.isAvailable ? ESchema.InStock : ESchema.SoldOut; // Sold out by default.
    },
    microdataFormattedPrice(): string {
      const allowedPartTypes: Set<Intl.NumberFormatPartTypes> = new Set([
        ENumberFormatPart.Decimal,
        ENumberFormatPart.Fraction,
        ENumberFormatPart.Integer,
      ]);
      const formattedPrice = this.priceInParts
        .filter(({ type }) => allowedPartTypes.has(type))
        .map(({ type, value }) => {
          if (type === ENumberFormatPart.Decimal) {
            return '.';
          }

          return value;
        })
        .join('');

      return formattedPrice;
    },
  },
});
