export enum ESidebarComponentId {
  FormLogin = 'form-login',
  FormNewsletterOptinAfterRegister = 'form-newsletter-optin-after-register',
  FormRegister = 'form-register',
  FormRegisterAfterOneClick = 'form-register-after-one-click',
  SidebarNavigationMain = 'navigation-main',
  SidebarNavigationUser = 'navigation-user',
  SidebarNavigationUserAccount = 'navigation-user-account',
  SidebarNavigationUserShop = 'navigation-user-shop',
}
