
import Vue, { PropType } from 'vue';

import { ESearchInsightEvent } from '@/domain/core/algolia/types';
import { ECurrencyCode } from '@/domain/pricing/types';
import { Product } from '@/domain/product/types';
import { _ga4ProductAdapter } from '@/infrastructure/externalServices/ga4/adapters/ga4ProductAdapter';
import { EGtmEventEcommerce } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

export default Vue.extend({
  name: 'ProductWishlist',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  data() {
    return {
      buttonDisplayState: false,
      isBoardCreationModalOpen: false,
      isDropdownOpen: false,
      isLoading: false,
    };
  },
  computed: {
    buttonPortalTarget(): string {
      return `add-to-board-dropdown-toggle-${this.productId}`;
    },
    isProductInWishlist(): boolean {
      return this.$accessor.wishlist.getWishlistProductIds.includes(this.productId);
    },
    productId(): string {
      const { legacyId, id } = this.product;

      return legacyId || id; // WARNING: legacyId still needs to be supported.
    },
    shouldDisplayDropdown(): boolean {
      return this.isDropdownOpen && this.isProductInWishlist;
    },
  },
  methods: {
    async addOrRemoveProductFromWishlist(): Promise<void> {
      try {
        this.setLoadingState(true);

        if (this.isProductInWishlist) {
          await this.$accessor.wishlist.removeProduct(this.productId);
          this.setButtonDisplayState(false);
        } else {
          await this.$accessor.wishlist.addProduct({ productId: this.productId });
          this.handleTracking();
          this.setButtonDisplayState(true);
        }
      } catch (err) {
        this.$errorMonitor.report(err, 'error');
      } finally {
        this.setLoadingState(false);
      }
    },
    handleClickOnAddToWishlistButton(): void {
      if (this.$accessor.user.authenticated) {
        this.addOrRemoveProductFromWishlist();
      }
    },
    handleTracking(): void {
      const fmtProduct = _ga4ProductAdapter(this.product);

      if (this.product?.__queryID) {
        this.$services.algoliaSearchInsights.sendConversionEvent(ESearchInsightEvent.AddToWishlist, this.product);
      }

      this.$gtm.push({ ecommerce: null });
      this.$gtm.push({
        event: EGtmEventEcommerce.AddToWishlist,
        ecommerce: {
          value: this.product?.pricing?.specialPrice || this.product?.pricing?.price || 0,
          currency: this.product.pricing?.currency || ECurrencyCode.EUR,
          items: [fmtProduct],
        },
      });
    },
    closeDropdown(): void {
      this.isDropdownOpen = false;
    },
    closeBoardCreationModal(): void {
      this.isBoardCreationModalOpen = false;
    },
    openBoardCreationModal(): void {
      this.isBoardCreationModalOpen = true;
    },
    openDropdown(): void {
      this.isDropdownOpen = true;
    },
    setButtonDisplayState(state: boolean): void {
      this.buttonDisplayState = state;
    },
    setLoadingState(state: boolean): void {
      this.isLoading = state;
    },
  },
});
