
import Vue, { PropType, VueConstructor } from 'vue';

import { Search } from '@/domain/search/types';
import currentRefinements from '@/mixins/currentRefinements';

export default (Vue as VueConstructor<Vue & InstanceType<typeof currentRefinements>>).extend({
  name: 'SearchSubtitle',
  mixins: [currentRefinements],
  props: {
    query: {
      required: true,
      type: Object as PropType<Search['query']>,
    },
  },
  computed: {
    subtitle(): string {
      const facetFiltersRefinements = this.getFacetFilterDisplayValues(this.query.facetFilters);
      const numericFiltersRefinements = this.getNumericFilterDisplayValues(this.query.numericFilters);

      return [...facetFiltersRefinements, ...numericFiltersRefinements].join(', ');
    },
  },
  methods: {
    getNumericFilterDisplayValues(numericFilters: string[]): string[] {
      const numericRefinements: string[] = [];

      for (const numericFilter of numericFilters) {
        const [attribute, operator, value] = numericFilter.split(/([<>]=?)/);

        numericRefinements.push(this.$_currentRefinements_getRefinementDisplayValue(attribute, operator + value));
      }

      return numericRefinements;
    },
    getFacetFilterDisplayValues(facets: (string | string[])[]): string[] {
      const rawRefinements = this.getRawRefinements(facets);
      const refinements: string[] = [];

      for (const attribute in rawRefinements) {
        const values: string[] = rawRefinements[attribute];

        for (const value of values) {
          const displayValue: string = this.$_currentRefinements_getRefinementDisplayValue(attribute, value);

          refinements.push(displayValue);
        }
      }

      return refinements;
    },
    getRawRefinements(facets: (string | string[])[], rawRefinements: Record<string, string[]> = {}): Record<string, string[]> {
      for (const facet of facets) {
        if (Array.isArray(facet)) {
          this.getRawRefinements(facet, rawRefinements);

          continue;
        }

        const [facetName, label] = facet.split(':');

        if (!Object.prototype.hasOwnProperty.call(rawRefinements, facetName)) {
          rawRefinements[facetName] = [];
        }

        rawRefinements[facetName].push(label);
      }

      return rawRefinements;
    },
  },
});
