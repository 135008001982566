import { actionTree, getterTree, mutationTree } from 'typed-vuex';

import { EColor } from '@/domain/core/Color.enum';
import { EUi as MutationType } from '@/domain/core/store/MutationType.enum';
import { HeaderDataAccentColor } from '@/infrastructure/core/service/data/HeaderData.interface';
import { SidebarComponents, UiState } from '@/infrastructure/core/store/modules/Ui.interface';
import { accessorType } from '@/store';
import { _hexToHSL } from '@/utilities/colors/hexToHSL';

export const state = (): UiState => ({
  accentColor: {
    color: '',
    fontColor: '',
  },
  backdrop: {
    isVisible: false,
  },
  sidebar: {
    currentComponent: null,
    isOpen: false,
    previousComponent: null,
  },
  modal: {
    isOpen: false,
  },
});

export const getters = getterTree(state, {
  getAccentColor: ({ accentColor }): string => _hexToHSL(accentColor.color) || `var(${EColor.Terracotta})`,
  getAccentFontColor: ({ accentColor }): string => _hexToHSL(accentColor.fontColor) || `var(${EColor.White})`,
});

export const mutations = mutationTree(state, {
  [MutationType.CLOSE_MODAL]: (state): void => {
    state.modal.isOpen = false;
  },
  [MutationType.CLOSE_SIDEBAR]: (state): void => {
    state.sidebar.isOpen = false;
  },
  [MutationType.HIDE_BACKDROP]: (state): void => {
    state.backdrop.isVisible = false;
  },
  [MutationType.OPEN_MODAL]: (state): void => {
    state.modal.isOpen = true;
  },
  [MutationType.OPEN_SIDEBAR]: (state): void => {
    state.sidebar.isOpen = true;
  },
  [MutationType.SET_ACCENT_COLOR]: (state, color: string): void => {
    state.accentColor.color = color;
  },
  [MutationType.SET_ACCENT_FONT_COLOR]: (state, color: string): void => {
    state.accentColor.fontColor = color;
  },
  [MutationType.SET_SIDEBAR_COMPONENT]: (state, options: SidebarComponents | null) => {
    state.sidebar.currentComponent = options?.currentComponent || null;
    state.sidebar.previousComponent = options?.previousComponent || null;
  },
  [MutationType.SHOW_BACKDROP]: (state): void => {
    state.backdrop.isVisible = true;
  },
});

export const actions = actionTree({ state, getters, mutations }, {
  closeDialog(): void {
    const accessor: typeof accessorType = this.app.$accessor;

    accessor.ui.HIDE_BACKDROP();
    accessor.ui.CLOSE_SIDEBAR();
    accessor.ui.SET_SIDEBAR_COMPONENT(null);
    accessor.ui.CLOSE_MODAL();
  },
  openSidebar(_, options: SidebarComponents): void {
    if (options?.currentComponent) {
      const accessor: typeof accessorType = this.app.$accessor;

      accessor.ui.SET_SIDEBAR_COMPONENT(options);
      accessor.ui.OPEN_SIDEBAR();
      accessor.ui.SHOW_BACKDROP();
    }
  },
  openModal(_): void {
    const accessor: typeof accessorType = this.app.$accessor;

    accessor.ui.OPEN_MODAL();
    accessor.ui.SHOW_BACKDROP();
  },
  setAccentColor(_, { color, fontColor, isActive }: HeaderDataAccentColor): void {
    if (isActive) {
      const accessor: typeof accessorType = this.app.$accessor;

      accessor.ui.SET_ACCENT_COLOR(color);
      accessor.ui.SET_ACCENT_FONT_COLOR(fontColor);
    }
  },
});
