export const SchemaDotOrgUrl = 'https://schema.org';

export enum ESchema {
  AggregateRating = 'https://schema.org/AggregateRating',
  BreadcrumbList = 'https://schema.org/BreadcrumbList',
  InStock = 'https://schema.org/InStock',
  ListItem = 'https://schema.org/ListItem',
  Offer = 'https://schema.org/Offer',
  Product = 'https://schema.org/Product',
  SoldOut = 'https://schema.org/SoldOut',
  Thing = 'https://schema.org/Thing',
}

export enum ESchemaProperty {
  Context = '@context',
  Type = '@type',
}

export enum ESchemaListItem {
  ItemListElement = 'itemListElement',
}

export enum ESchemaOffer {
  Availability = 'availability',
  Price = 'price',
  PriceCurrency = 'priceCurrency',
}

export enum ESchemaProduct {
  AggregateRating = 'aggregateRating',
  Image = 'image',
  Name = 'name',
  Offers = 'offers',
  Url = 'url',
}

export enum ESchemaThing {
  Item = 'item',
  Name = 'name',
  Position = 'position',
}

export enum ESchemaAggregateRating {
  RatingValue = 'ratingValue',
  ReviewCount = 'reviewCount',
}

export enum ESchemaType {
  AggregateRating = 'AggregateRating',
  Brand = 'Brand',
  MonetaryAmount = 'MonetaryAmount',
  Offer = 'Offer',
  OfferShippingDetails = 'OfferShippingDetails',
  Organization = 'Organization',
  Product = 'Product',
  SearchAction = 'SearchAction',
  Website = 'WebSite',
}

// Source : https://schema.org/OfferItemCondition
export enum ESchemaOfferItemCondition {
  NewCondition = 'NewCondition',
  UsedCondition = 'UsedCondition',
}
