import { Plugin } from '@nuxt/types';

import HttpService from '@/services/http.service';

const httpPlugin: Plugin = ({ $axios }, inject) => {
  const httpService = new HttpService($axios);

  inject('http', httpService);
};

export default httpPlugin;
