import { render, staticRenderFns } from "./SearchSaved.vue?vue&type=template&id=a41cc908"
import script from "./SearchSaved.vue?vue&type=script&lang=ts"
export * from "./SearchSaved.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchSubtitle: require('/home/runner/work/webapp/webapp/components/atoms/search/SearchSubtitle.vue').default,BaseLink: require('/home/runner/work/webapp/webapp/components/atoms/link/BaseLink.vue').default})
