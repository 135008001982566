import { Plugin } from '@nuxt/types';
import algoliasearch from 'algoliasearch/lite';

const algoliaPlugin: Plugin = ({ $config }, inject) => {
  const algoliaClient = algoliasearch($config.algolia.appId, $config.algolia.apiKey, {
    queryParameters: {
      clickAnalytics: 'true',
      analytics: 'true',
    },
  });

  inject('algolia', algoliaClient);
};

export default algoliaPlugin;
