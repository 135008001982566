var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.componentType.name,_vm._b({tag:"component",staticClass:"flex flex-col items-center flex-none gap-2 text-sm text-center",class:{
    'text-black': !_vm.isDisabled,
    'text-gray-darker': _vm.isDisabled
  }},'component',_vm.componentType.props,false),[_c('span',{staticClass:"flex items-center rounded-full size-6",class:{
      'bg-black text-white': !_vm.isDisabled,
      'bg-gray-light text-gray-darker': _vm.isDisabled
    }},[_c(_vm.iconName,{tag:"component",staticClass:"m-auto",class:{
        'size-4': !_vm.isChecked,
        'size-6': _vm.isChecked
      }})],1),_vm._v("\n  "+_vm._s(_vm.step.label)+"\n")])
}
var staticRenderFns = []

export { render, staticRenderFns }