import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { ApiItemBase } from '@/domain/core/api/ApiItemBase.interface';
import { EEmbedsName, Embeds } from '@/domain/core/api/embeds.interface';
import { PaginatedList } from '@/domain/core/api/types';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import { ProductIdentifier, ProductSelectionTriage } from '@/domain/product/types';
import { SelectionIdentifier, SelectionRepositoryInterface } from '@/domain/selection/selectionRepository.interface';
import { ESelection, Selection, SelectionItem, SelectionLegacy } from '@/domain/selection/types';
import { transform, transformList, transformListPaginated } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

const resourcePath = '/selections';
const triageResourcePath = `${resourcePath}-triage`;

export default class SelectionRepository implements SelectionRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;
  readonly #security: SecurityTokenProvider;

  constructor(httpService: HttpService, basePath: string, security: SecurityTokenProvider) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#security = security;
  }

  async addProductToTriage(productId: ProductIdentifier): Promise<ProductSelectionTriage> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiItemBase<ProductSelectionTriage>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Post,
      path: `${this.#basePath}${triageResourcePath}`,
      payload: {
        productId,
      },
    });

    return transform(content);
  }

  async getSelection(identifier: SelectionIdentifier): Promise<Selection> {
    const { content } = await this.#httpService.request<ApiItemBase<Selection>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}/${identifier}`,
    });

    return transform(content);
  }

  async getSelectionItems(identifier: SelectionIdentifier, page: number | null = null, limit = 64): Promise<PaginatedList<SelectionItem>> {
    const { content } = await this.#httpService.request<ApiBase<SelectionItem>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}/${identifier}/items`,
      queryParams: {
        limit,
        page,
      },
    });

    return transformListPaginated(content);
  }

  async getSelectionLegacy(slug: `${ESelection}`): Promise<SelectionLegacy> {
    const { content } = await this.#httpService.request<ApiBase<SelectionLegacy, Pick<Embeds, EEmbedsName.Products>>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}`,
      queryParams: {
        slug,
        embeds: EEmbedsName.Products,
      },
    });

    const [selection] = transformList(content?.items) || [];

    return selection;
  }

  async getTriageContent(): Promise<ProductSelectionTriage[]> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiBase<ProductSelectionTriage>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${triageResourcePath}`,
    });

    return transformList(content?.items) || [];
  }

  async removeProductFromTriage(productId: ProductIdentifier): Promise<void> {
    const token = this.#security.getToken();

    await this.#httpService.request({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Delete,
      path: `${this.#basePath}${triageResourcePath}/${productId}`,
    });
  }
}
