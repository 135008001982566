export enum EAddress {
  SET_ADDRESSES = 'SET_ADDRESSES',
  SET_BILLING_ID = 'SET_BILLING_ID',
  SET_FETCH_STATE = 'SET_FETCH_STATE',
  SET_SHIPPING_ID = 'SET_SHIPPING_ID',
}

export enum EHeader {
  SET_HEADER_BANNER = 'SET_HEADER_BANNER',
  SET_NAVIGATION = 'SET_NAVIGATION',
}

export enum EFeatureFlags {
  SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS',
}

export enum ERoot {
  SET_USER = 'SET_USER',
}

export enum ENotifications {
  SET_CART_COUNT = 'SET_CART_COUNT',
  SET_MESSAGES_COUNT = 'SET_MESSAGES_COUNT',
  SET_PRODUCTS_COUNT = 'SET_PRODUCTS_COUNT',
}

export enum EPricing {
  SET_CURRENCIES = 'SET_CURRENCIES',
  SET_CURRENCY_CODE = 'SET_CURRENCY_CODE',
}

export enum EUi {
  CLOSE_MODAL = 'CLOSE_MODAL',
  CLOSE_SIDEBAR = 'CLOSE_SIDEBAR',
  HIDE_BACKDROP = 'HIDE_BACKDROP',
  OPEN_MODAL = 'OPEN_MODAL',
  OPEN_SIDEBAR = 'OPEN_SIDEBAR',
  SET_ACCENT_COLOR = 'SET_ACCENT_COLOR',
  SET_ACCENT_FONT_COLOR = 'SET_ACCENT_FONT_COLOR',
  SET_SIDEBAR_COMPONENT = 'SET_SIDEBAR_COMPONENT',
  SHOW_BACKDROP = 'SHOW_BACKDROP',
}

export enum EWishlist {
  SET_WISHLIST = 'SET_WISHLIST',
}

export enum EShop {
  SET_SHOP_STATUS = 'SET_SHOP_STATUS',
}

export enum ESelection {
  ADD_PRODUCT_TO_TRIAGE_PRODUCTS = 'ADD_PRODUCT_TO_TRIAGE_PRODUCTS',
  REMOVE_PRODUCT_FROM_TRIAGE_PRODUCTS_BY_ID = 'REMOVE_PRODUCT_FROM_TRIAGE_PRODUCTS_BY_ID',
  SET_TRIAGE_PRODUCTS = 'SET_TRIAGE_PRODUCTS',
}
