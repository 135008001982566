import { Plugin } from '@nuxt/types';

import { ECookie } from '@/domain/core/http/Cookie.enum';
import abTestsConfig from '@/infrastructure/core/abTests/config.json';
import { ABTest, ABTestCookie, ABTestName, ABTestService } from '@/infrastructure/core/abTests/types';

const abTestsPlugin: Plugin = ({ $cookies }, inject) => {
  const userAbTestsCookie: ABTestCookie = $cookies.get(ECookie.UserAbTests) || {};
  const abTests = Object.fromEntries(
    Object.entries(abTestsConfig as ABTest)
      .map(([testName, testValue]) => {
        const propertyValue = {
          name: testName,
          isDefault: true,
          isVariant: false,
        };
        const isTestVariantEnabled = testValue.variant === userAbTestsCookie?.[testName as ABTestName];

        if (isTestVariantEnabled) {
          propertyValue.isDefault = false;
          propertyValue.isVariant = true;
        }

        return [testName, propertyValue];
      }),
  ) as ABTestService;

  inject('abTests', abTests);
};

export default abTestsPlugin;
