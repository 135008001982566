export enum EHttpStatusCode {
  BadRequest = 400,
  Created = 201,
  Forbidden = 403,
  MovedPermanently = 301,
  Found = 302,
  InternalServerError = 500,
  NotFound = 404,
  OK = 200,
  TooManyRequests = 429,
  Unauthorized = 401,
}
