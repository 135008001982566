
import Vue from 'vue';

import IcCross from '@/assets/svg/ic_cross.svg?inline';
import IcLoader from '@/assets/svg/ic_loader.svg?inline';
import { ELabelledById } from '@/domain/core/a11y/LabelledById.enum';
import { WishlistBoard, WishlistProduct } from '@/domain/wishlist/types';

export default Vue.extend({
  name: 'WishlistBoardsDropdown',
  components: {
    IcCross,
    IcLoader,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ELabelledById,
      isLoading: false,
      isMouseOver: false,
      loadingBoardId: null as WishlistBoard['id'],
    };
  },
  computed: {
    activeBoardId(): WishlistBoard['id'] {
      let activeBoard = null;

      for (const board of this.boards) {
        const hasBoard = board?.products?.find(({ id }: WishlistProduct) => id === this.productId);

        if (hasBoard) {
          activeBoard = board.id;

          break;
        }
      }

      return activeBoard;
    },
    boards(): WishlistBoard[] {
      return this.$accessor.wishlist.boards;
    },
  },
  mounted() {
    this.handleDropdownDisplayOnMouseover();
  },
  methods: {
    async addProductToBoard(id: WishlistBoard['id']): Promise<void> {
      const defaultBoardId = 'default';
      const boardId = id === this.activeBoardId ? defaultBoardId : id;

      try {
        this.setLoadingBoardId(id);

        await this.$accessor.wishlist.addProduct({
          boardId,
          productId: this.productId,
        });
      } catch (err) {
        this.$errorMonitor.report(err, 'error');
      } finally {
        this.setLoadingBoardId(null);
      }
    },
    closeDropdown(): void {
      this.$emit('close');
    },
    handleBoardCreation(): void {
      this.$emit('create-board');
      this.closeDropdown();
    },
    handleDropdownDisplayOnMouseover(): void {
      const dropdownDisplayTimeInMs = 2000;
      const isDesktop = window.matchMedia('(min-width: 768px)').matches; // TODO: create a global and unique listener for the app.

      if (isDesktop) {
        setTimeout(() => {
          if (this.isMouseOver) {
            this.handleDropdownDisplayOnMouseover();
          } else {
            this.closeDropdown();
          }
        }, dropdownDisplayTimeInMs);
      }
    },
    setLoadingBoardId(boardId: WishlistBoard['id']): void {
      this.loadingBoardId = boardId;
    },
    setMouseOverState(state: boolean): void {
      this.isMouseOver = state;
    },
  },
});
