import { PaginatedList, PaginationQueryParameters } from '@/domain/core/api/types';
import { LegacyCategories, Product, ProductIdentifier, UsableDeliveryOption } from '@/domain/product/types';

export interface DeliveryOptionsRequestParams {
  quantity: string;
  shippingAddressCity: string;
  shippingAddressCountry: string;
  shippingAddressPostcode: string;
  shippingAddressStreetname?: number | null;
}

export type SimilarProductsRequestParams = PaginationQueryParameters & { onlyPriceReduced?: boolean };

export enum EProductsListIdentifierType {
  LegacyIds = 'legacyIds',
  Skus = 'skus',
}

export interface ProductRepositoryInterface {
  countProducts(userId: string, statuses: string[]): Promise<number>;
  getCategories(slug: string | null, parentSlug: string | null): Promise<LegacyCategories>;
  getCategory(id: string): Promise<LegacyCategories>;
  getProduct(id: ProductIdentifier): Promise<Product>;
  getProductLite(id: ProductIdentifier): Promise<Product>;
  getProducts(
    identifiersList: string[],
    identifierType?: EProductsListIdentifierType,
    parameters?: PaginationQueryParameters
  ): Promise<PaginatedList<Product> | null>;
  getSimilarProducts(id: ProductIdentifier, parameters?: SimilarProductsRequestParams): Promise<PaginatedList<Product>>;
  getUsableDeliveryOptions(productId: string, deliveryOfferRequestParams: DeliveryOptionsRequestParams): Promise<UsableDeliveryOption[]>;
}
