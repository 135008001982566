
import Vue, { PropType } from 'vue';

import { MainMenuItem } from '@/infrastructure/core/components/navigation/MainMenuItem.interface';

const hoverDelayInMs = 200;

export default Vue.extend({
  name: 'MainNavigation',
  props: {
    navigation: {
      type: Array as PropType<MainMenuItem[]>,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
      isMouseOut: false,
      hoverDelayTimer: null as ReturnType<typeof setTimeout> | null,
    };
  },
  methods: {
    clearTimer() {
      if (this.hoverDelayTimer) {
        clearTimeout(this.hoverDelayTimer);
      }
    },
    handleActiveMenu(index: number) {
      this.clearTimer();
      this.hoverDelayTimer = setTimeout(() => {
        this.activeIndex = index;
        this.isMouseOut = false;
      }, hoverDelayInMs);
    },
    handleMouseLeave(): void {
      this.clearTimer();
      this.isMouseOut = true;
    },
  },
});
