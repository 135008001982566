
import Vue, { PropType } from 'vue';

import { PromoBlock } from '@/domain/edito/types';
import { MainMenuItem, SeeAll } from '@/infrastructure/core/components/navigation/MainMenuItem.interface';
import { EGtmEventHeader } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { ClickEventPayload } from '@/infrastructure/externalServices/gtm/generic/ClickEvent.interface';
import { imagePlaceholderId } from '@/infrastructure/externalServices/imageCdn/types';

const minSubmenuItemsCount = 10;

export default Vue.extend({
  name: 'NavigationMainSubmenu',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    seeAll: {
      type: Object as PropType<SeeAll>,
      default: (): Partial<SeeAll> => ({}),
    },
    submenu: {
      type: Array as PropType<MainMenuItem[]>,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
    promoBlocks: {
      type: Array as PropType<PromoBlock[]>,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: -1,
      EGtmEventHeader,
      focusedNode: null as HTMLAnchorElement | null,
      imagePlaceholderId,
      isMouseOut: false,
      submenuItemsCount: minSubmenuItemsCount,
    };
  },
  computed: {
    transitionMode(): string | null {
      return this.level > 1 ? 'out-in' : null;
    },
    transitionName(): string {
      return this.level > 1 ? 'fade' : 'fade-menu';
    },
    computedStyle(): Record<string, string> {
      const itemHeight = 24;
      const minMenuHeight = 380;
      const topOffset = 120;
      const calcHeight = this.submenuItemsCount * itemHeight + topOffset;
      const minHeight = `${calcHeight < minMenuHeight ? minMenuHeight : calcHeight}px`;

      return {
        minHeight,
        '--items-count': `${this.submenuItemsCount}`,
      };
    },
  },
  methods: {
    updateSubMenuCount(index: number): void {
      if (!Number.isNaN(index)) {
        const level2 = this.submenu[index];

        this.submenuItemsCount = level2?.child?.length || minSubmenuItemsCount;
      }
    },
    updateActiveMenu(index: number): void {
      this.isMouseOut = false;
      this.activeIndex = index;
    },
    resetActiveIndex(): void {
      this.activeIndex = -1;
    },
    handleHover(index: number): void {
      this.updateSubMenuCount(index);
      this.updateActiveMenu(index);
    },
    handleMouseLeave(): void {
      this.isMouseOut = true;
      this.resetActiveIndex();
      this.submenuItemsCount = minSubmenuItemsCount;

      if (this.focusedNode) {
        this.focusedNode.blur();
        this.focusedNode = null;
      }
    },
    handleFocus(index: number, $event: Event) {
      $event.stopPropagation();
      this.updateSubMenuCount(index);
      this.updateActiveMenu(index);

      this.focusedNode = $event.target as HTMLAnchorElement;
    },
    trackSubmenuClick(event: string, payload: ClickEventPayload): void {
      this.$gtm.push({
        event,
        ...payload,
      });
    },
  },
});
