var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseLink',{staticClass:"text-tiny",class:[
    {
      'text-terracotta': _vm.isPromo,
      'uppercase p-2 pb-4': !_vm.level,
      'focus:outline-none focus:shadow-[0_0_2px_1px_rgba(0,0,0,0.2)] w-full relative block': _vm.level,
    },
    _vm.cssSpacingClasses,
  ],attrs:{"href":_vm.url,"is-unstyled":!!_vm.level},on:{"click":_vm.handleClick,"focus":_vm.handleFocus}},[_vm._v("\n  "+_vm._s(_vm.value)+"\n  "),(_vm.isNew)?_c('sup',{staticClass:"text-terracotta",class:{
      'absolute top-0 right-0 block': !_vm.level,
    }},[_vm._v("\n    "+_vm._s(_vm.$t('header.mainNavigation.linkNewLabel'))+"\n  ")]):_vm._e(),_vm._v(" "),(!_vm.level)?_c('span',{staticClass:"absolute bottom-0 left-2 w-[calc(100%-theme(space.4))] h-px transition-transform scale-x-0 bg-black group-hover:scale-x-100",attrs:{"aria-hidden":"true","data-testid":"navigation-main-hover-border"}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }