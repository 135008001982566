import { legacyPriceAdapter } from '@/adapters/price.adapter';
import { ApiLegacyProduct, Product, ProductCore } from '@/domain/product/types';
import { ShopDetails } from '@/domain/shop/types';
import { AlgoliaProduct } from '@/infrastructure/externalServices/algolia/types';

export const legacyProductAdapter = (product: ApiLegacyProduct): Partial<Product> => ({
  mainPicture: {
    id: product?.image,
  },
  pricing: {
    currency: 'EUR',
    id: '',
    isNegotiable: false,
    pro: {
      isActive: !!Number.parseInt(product?.isProPrice, 10),
      price: legacyPriceAdapter(product?.proPrice),
      discount: 0, // TODO: find out what it is 👀
    },
    reservedPrice: 0,
    specialPrice: legacyPriceAdapter(product?.specialPrice),
    price: legacyPriceAdapter(product?.price),
  },
  qualityLevel: product?.flags?.quality_mark || 0,
  sku: product?.sku,
  slug: product?.slug,
  title: product?.title,
});

export const algoliaProductAdapter = (product: AlgoliaProduct): ProductCore => {
  const [firstName, lastNameInitial] = product?.sellerName?.split(' ') || [];

  return {
    __position: product?.__position,
    __queryID: product?.__queryID,
    objectID: product?.id,
    id: product?.id,
    isHandmade: product?.isHandmade,
    status: product?.status,
    mainPicture: {
      id: product?.pictureId,
    },
    pricing: {
      currency: product?.currency,
      id: '',
      isNegotiable: product?.isNegotiable || false,
      pro: {
        isActive: !!product?.isProPrice,
        price: product?.proPrice || 0,
        discount: 0, // TODO: find out what it is 👀
      },
      reservedPrice: 0,
      specialPrice: product?.specialPrice || 0,
      price: product?.price || 0,
    },
    qualityLevel: product?.qualityMark || 0,
    shop: {
      user: {
        firstName,
        lastNameInitial,
      },
      statistics: {
        rating: product?.sellerScoreAverageRating,
        totalReviews: product?.sellerScoreReviewsCount,
      },
    } as ShopDetails, // 😬
    sku: product?.sku,
    slug: product?.slug,
    title: product?.title,
  };
};
