import { SecurityToken, SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import { accessorType } from '@/store';

export class StoreTokenProvider implements SecurityTokenProvider {
  readonly #store: typeof accessorType;

  constructor(store: typeof accessorType) {
    this.#store = store;
  }

  getToken(): SecurityToken {
    const token = this.#store?.user?.token;

    if (!token) {
      throw new Error('No user token provided');
    }

    return token;
  }
}

export class MockStoreTokenProvider implements SecurityTokenProvider {
  readonly #token: SecurityToken;

  constructor(token: SecurityToken) {
    this.#token = token;
  }

  getToken(): SecurityToken {
    return this.#token;
  }
}
