
import Vue, { PropType } from 'vue';

import { BaseSelectOption } from '@/infrastructure/core/components/input/BaseSelectOption.interface';

export default Vue.extend({
  name: 'BaseSelect',
  props: {
    cssSpacingClasses: {
      type: String,
      default: '',
    },
    dataTestId: {
      type: String,
      default: 'base-select',
    },
    error: {
      type: String as PropType<string | null>,
      default: null,
    },
    inputId: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      default: '',
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<BaseSelectOption[]>,
      required: true,
    },
    placeholder: {
      type: String as PropType<string | null>,
      default: null,
    },
    selectedValue: {
      type: String,
      required: true,
    },
  },
  computed: {
    cssClasses(): Record<string, string | boolean> {
      return {
        'text-gray-darker bg-gray cursor-not-allowed': this.isDisabled,
        'ring-gray focus:ring-gray-darker': !this.error,
        'ring-red focus:ring-red': !!this.error,
      };
    },
    hasPrefix(): boolean {
      return !!this.$slots.prefix;
    },
    modelSelectedValue: {
      get(): string {
        return this.selectedValue;
      },
      set(value: string): void {
        this.$emit('update:selected-value', value);
      },
    },
  },
});
