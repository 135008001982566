import VueRouter from 'vue-router';

import { EFacetNameRefinementList } from '@/domain/core/algolia/types';
import { ELanguage } from '@/domain/core/Language.enum';
import { Category, ECategoryLevelDepth, LegacyCategory } from '@/domain/product/types';
import { Link } from '@/infrastructure/core/components/link/Link.interface';

export interface CategoryRouterInterface {
  getBreadcrumbs(category: LegacyCategory, breadcrumbs?: Link[]): Link[];
  getBreadcrumbsFromCategories(categories: Category[]): Link[];
  getPath(category: LegacyCategory, locale?: `${ELanguage}`): string | null;
}

export default class CategoryRouter implements CategoryRouterInterface {
  #categoryDepthToCategoryParam: Record<ECategoryLevelDepth, EFacetNameRefinementList> = {
    [ECategoryLevelDepth.Level1]: EFacetNameRefinementList.CategoryLevel1,
    [ECategoryLevelDepth.Level2]: EFacetNameRefinementList.CategoryLevel2,
    [ECategoryLevelDepth.Level3]: EFacetNameRefinementList.CategoryLevel3,
  };

  #categoryRouteName = 'category';
  #router: VueRouter;

  constructor(router: VueRouter) {
    this.#router = router;
  }

  getBreadcrumbs(category: LegacyCategory, breadcrumbs: Link[] = []): Link[] {
    const localBreadcrumbs = [...breadcrumbs];

    if (!category?.name || !category?.slug) {
      return localBreadcrumbs;
    }

    localBreadcrumbs.unshift({
      label: category.name,
      props: {
        href: this.getPath(category),
        to: {
          name: this.#categoryRouteName,
          params: this.#getRouteParams(category),
        },
      },
    });

    return category.parent ? this.getBreadcrumbs(category.parent, localBreadcrumbs) : localBreadcrumbs;
  }

  getBreadcrumbsFromCategories(categories: Category[]): Link[] {
    return categories.map((category) => {
      const slugsTreeToCategoryParamList = category.slugsTree.map((slug, index) => {
        const level: ECategoryLevelDepth = index + 1;
        const paramName = this.#categoryDepthToCategoryParam[level];

        return { [paramName]: slug };
      });
      const params: Record<string, string> = Object.assign({}, ...slugsTreeToCategoryParamList);
      const categoryLocation = {
        name: this.#categoryRouteName,
        params,
      };

      return {
        label: category.name,
        props: {
          href: this.#router.resolve(categoryLocation)?.href || null,
          to: categoryLocation,
        },
      };
    });
  }

  getPath(category: LegacyCategory, locale?: `${ELanguage}`): string | null {
    const categoryLocation = {
      name: this.#categoryRouteName,
      params: this.#getRouteParams(category, {}, locale),
    };

    return this.#router.resolve(categoryLocation)?.href || null;
  }

  #getRouteParams(
    category: LegacyCategory,
    params: Record<string, string> = {},
    locale: `${ELanguage}` | null = null,
  ): Record<string, string> {
    const localParams = { ...params };

    if (category.level === null || category.slug === null || (locale && category?.i18nSlugs?.[locale] === undefined)) {
      return {};
    }

    const paramKey = this.#categoryDepthToCategoryParam[category.level];

    localParams[paramKey] = locale ? category?.i18nSlugs?.[locale] as string : category.slug;

    return category.parent ? this.#getRouteParams(category.parent, localParams, locale) : localParams;
  }
}
