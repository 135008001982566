import { legacyCategoryAdapter } from '@/adapters/category.adapter';
import { ProductRepositoryInterface } from '@/domain/product/productRepository.interface';
import { LegacyCategory } from '@/domain/product/types';

export interface CategoryServiceInterface {
  get(id: string): Promise<LegacyCategory>;
  getBySlug(slug: string | null, parentSlug?: string | null): Promise<LegacyCategory>;
}

export class CategoryService implements CategoryServiceInterface {
  readonly #productRepository: ProductRepositoryInterface;
  readonly #i18nLocale: string;

  constructor(productRepository: ProductRepositoryInterface, i18nLocale: string) {
    this.#productRepository = productRepository;
    this.#i18nLocale = i18nLocale;
  }

  async get(id: string): Promise<LegacyCategory> {
    const content = await this.#productRepository.getCategory(id);

    return legacyCategoryAdapter(content.listing.elements[0], this.#i18nLocale);
  }

  async getBySlug(slug: string | null, parentSlug: string | null = null): Promise<LegacyCategory> {
    const content = await this.#productRepository.getCategories(slug, parentSlug);

    return legacyCategoryAdapter(content.listing.elements[0], this.#i18nLocale);
  }
}
