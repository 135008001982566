import { ECurrencyCode } from '@/domain/pricing/types';
import { Product } from '@/domain/product/types';
import { AlgoliaProduct } from '@/infrastructure/externalServices/algolia/types';
import { GA4EcommerceItem } from '@/infrastructure/externalServices/ga4/types';

// Source: https://www.npmjs.com/package/ga-ecommerce-tracking
export const _ga4ProductAdapter = (product: Partial<Product>, position: number = 0): GA4EcommerceItem => {
  const price = product?.pricing?.price || 0;
  const specialPrice = product?.pricing?.specialPrice || 0;
  const discount = specialPrice ? price - specialPrice : 0;
  let itemCategories = {};

  if (product?.categories) {
    const [itemCategory, itemCategory2, itemCategory3] = product.categories || [];

    itemCategories = {
      item_category: itemCategory?.name || '',
      item_category2: itemCategory2?.name || '',
      item_category3: itemCategory3?.name || '',
    };
  }

  return {
    currency: product?.pricing?.currency || ECurrencyCode.EUR,
    discount,
    index: position,
    item_id: product?.sku || '',
    item_name: product?.title || '',
    price,
    quantity: product?.quantity || 1,
    ...itemCategories,
  };
};

export const _ga4AlgoliaProductAdapter = (product: AlgoliaProduct, position: number = 0): GA4EcommerceItem => {
  const price = product?.price || 0;
  const specialPrice = product?.specialPrice || 0;
  const discount = specialPrice ? price - specialPrice : 0;

  return {
    currency: product?.currency || ECurrencyCode.EUR,
    discount,
    index: position,
    item_id: product?.sku || '',
    item_name: product?.title || '',
    price,
    quantity: product?.quantity || 1,
  };
};
