
import Vue from 'vue';

import Logo from '@/assets/svg/logo.svg?inline';
import LogoPro from '@/assets/svg/logo_pro.svg?inline';

export default Vue.extend({
  name: 'HeaderLite',
  components: {
    Logo,
    LogoPro,
  },
  computed: {
    isUserPro(): boolean {
      return this.$accessor.user.isPro;
    },
  },
});
