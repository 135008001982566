import { _isValidDateParameter } from '@/utilities/dates/isValidDateParameter';

type date = string | number | Date;

interface BetweenTwoDates {
  current?: date;
  end: date;
  start: date;
}

export const _isCurrentDateBetweenTwoDates = ({ current, end, start }: BetweenTwoDates): boolean => {
  let today = Date.now();

  if (current && _isValidDateParameter(current)) {
    today = new Date(current).getTime();
  }

  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();

  return today >= startTime && today <= endTime;
};
