
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'BaseField',
  props: {
    inputId: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String as PropType<string | null>,
      default: null,
    },
    subLabel: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
});
