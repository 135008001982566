import { Middleware } from '@nuxt/types';

import { ECookie } from '@/domain/core/http/Cookie.enum';
import { ERouteName } from '@/domain/core/Routes.enum';

const checkoutAuthorized: Middleware = ({ $accessor, $cookies, redirect }) => {
  const isAuthenticated = $accessor.user.authenticated;
  const hasCartId = !!$cookies.get(ECookie.UserCartId);

  if (!isAuthenticated || !hasCartId) {
    return redirect({ name: ERouteName.CheckoutCart });
  }
};

export default checkoutAuthorized;
