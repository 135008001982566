import { CartRepositoryInterface } from '@/domain/cart/cartRepository.interface';
import { ForumRepositoryInterface } from '@/domain/forum/forumRepository.interface';
import { ProductRepositoryInterface } from '@/domain/product/productRepository.interface';
import { EProductStatus } from '@/domain/product/types';
import { NotificationsCount } from '@/infrastructure/core/service/data/NotificationData.interface';

;

export class NotificationDataService {
  readonly #cartRepository: CartRepositoryInterface;
  readonly #forumRepository: ForumRepositoryInterface;
  readonly #productRepository: ProductRepositoryInterface;

  constructor(
    forumRepository: ForumRepositoryInterface,
    cartRepository: CartRepositoryInterface,
    productRepository: ProductRepositoryInterface,
  ) {
    this.#cartRepository = cartRepository;
    this.#forumRepository = forumRepository;
    this.#productRepository = productRepository;
  }

  async getNotifications({ cartId, userId }: {
      cartId: string | null;
      userId: string;
    }): Promise<NotificationsCount> {
    const notifications: NotificationsCount = {
      messages: {
        unreadThreadsCount: 0,
      },
      cart: {
        itemsCount: 0,
      },
      products: {
        pendingEditsCount: 0,
      },
    };

    const [
      threadsRequest,
      cartRequest,
      productRequest,
    ] = await Promise.allSettled([
      this.#forumRepository.getUnreadPrivateMessagesCount(),
      this.#cartRepository.get(cartId || ''),
      this.#productRepository.countProducts(userId, [EProductStatus.AwaitingChanges]),
    ]);

    if (threadsRequest?.status === 'fulfilled') {
      notifications.messages.unreadThreadsCount = threadsRequest?.value || 0;
    }

    if (cartRequest?.status === 'fulfilled') {
      notifications.cart.itemsCount = cartRequest?.value?.products?.length || 0;
    }

    if (productRequest?.status === 'fulfilled') {
      notifications.products.pendingEditsCount = productRequest?.value || 0;
    }

    return notifications;
  }
}
