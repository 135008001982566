import { ApiLegacyBase } from '@/domain/core/api/ApiLegacyBase.interface';
import { EHeaderLegacy } from '@/domain/core/http/HeaderLegacy';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { EditoRepositoryInterface } from '@/domain/edito/editoRepository.interface';
import { EHomepageStatus, EditoHeader, EditoHomepage, EditoMenu, EditoSelection } from '@/domain/edito/types';
import { HttpRequest } from '@/infrastructure/core/http/HttpRequest.interface';
import HttpService from '@/services/http.service';

export default class EditoRepository implements EditoRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;
  readonly #i18nLocale: string;

  constructor(httpService: HttpService, basePath: string, i18nLocale: string) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#i18nLocale = i18nLocale;
  }

  async getHeader(): Promise<EditoHeader> {
    const { content } = await this.#httpService.request<ApiLegacyBase<EditoHeader>>({
      headers: {
        [EHeaderLegacy.Locale]: this.#i18nLocale,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}/header`,
    });

    return content?.content;
  }

  async getHomepage(previewId: string | null = null): Promise<EditoHomepage> {
    const queryParams: HttpRequest['queryParams'] = {};
    let path = `${this.#basePath}/homepage`;

    if (previewId) {
      path += `/${previewId}`;
    } else {
      queryParams.status = EHomepageStatus.Published;
    }

    const { content } = await this.#httpService.request<ApiLegacyBase<EditoHomepage>>({
      headers: {
        [EHeaderLegacy.Locale]: this.#i18nLocale,
      },
      method: EHttpMethod.Get,
      path,
      queryParams,
    });

    return content?.content;
  }

  async getMenu(): Promise<EditoMenu[]> {
    const { content } = await this.#httpService.request<ApiLegacyBase<EditoMenu[]>>({
      method: EHttpMethod.Get,
      path: `${this.#basePath}/menu`,
      queryParams: { version: this.#i18nLocale },
    });

    return content?.content;
  }

  async getSelection(): Promise<EditoSelection> {
    // TODO: handle locale
    const { content } = await this.#httpService.request<ApiLegacyBase<EditoSelection>>({
      method: EHttpMethod.Get,
      path: `${this.#basePath}/selection`,
    });

    return content?.content;
  }
}
