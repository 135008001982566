import { render, staticRenderFns } from "./NavigationUser.vue?vue&type=template&id=5047368f"
import script from "./NavigationUser.vue?vue&type=script&lang=ts"
export * from "./NavigationUser.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarToggle: require('/home/runner/work/webapp/webapp/components/molecules/sidebar/SidebarToggle.vue').default,NotificationDot: require('/home/runner/work/webapp/webapp/components/atoms/notification/NotificationDot.vue').default})
