export enum EHeader {
  Accept = 'accept',
  AcceptLanguage = 'accept-language',
  Authorization = 'authorization',
  CacheControl = 'cache-control',
  CloudfrontUserCountryCode = 'cloudfront-viewer-country', // Represents an ISO 3166-1 country code.
  CloudfrontUserIpAddress = 'x-real-ip',
  CloudfrontUserPostalCode = 'cloudfront-viewer-postal-code',
  Deprecation = 'deprecation',
  XForwardedFor = 'X-Forwarded-For',
}
