var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative",class:[
    {
      'flex': _vm.hasPrefix,
    },
    _vm.cssSpacingClasses
  ],attrs:{"data-testid":_vm.dataTestId}},[(_vm.hasPrefix)?_c('div',{staticClass:"flex items-center justify-center flex-none p-2 text-sm text-center border-r-0 ring-1 ring-inset",class:{
      ..._vm.cssClasses,
      'w-11': !_vm.isCompact,
      'w-10': _vm.isCompact,
    },attrs:{"data-testid":`${_vm.dataTestId}-prefix`}},[_vm._t("prefix")],2):_vm._e(),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelSelectedValue),expression:"modelSelectedValue"}],staticClass:"w-full p-3 pr-10 text-sm leading-6 bg-white bg-no-repeat bg-[right_0.8rem_top_55%] bg-[length:1rem_1rem] rounded-none appearance-none cursor-pointer ring-1 ring-gray ring-inset focus:outline-none invalid:text-gray-darker",class:{
      ..._vm.cssClasses,
      'block': !_vm.hasPrefix,
      'inline-block -ml-px': _vm.hasPrefix,
      'py-2': _vm.isCompact,
    },attrs:{"id":_vm.inputId,"disabled":_vm.isDisabled,"name":_vm.inputName,"required":_vm.isRequired},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.modelSelectedValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[(_vm.placeholder)?_c('option',{attrs:{"data-testid":"base-select-placeholder","disabled":"","hidden":"","selected":"","value":""}},[_vm._v("\n      "+_vm._s(_vm.placeholder)+"\n    ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.options),function({ label, value },index){return _c('option',{key:index,domProps:{"value":value}},[_vm._v("\n      "+_vm._s(label)+"\n    ")])})],2),_vm._v(" "),_c('TransitionCollapse',[(_vm.error)?_c('p',{staticClass:"text-xs text-red",attrs:{"data-testid":`${_vm.dataTestId}-error`}},[_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }