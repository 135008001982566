/**
 * Convert hex colors to HSL
 *
 * Source (refactored for better readability):
 * https://css-tricks.com/converting-color-spaces-in-javascript/#aa-hex-to-hsl
 */
export const _hexToHSL = (H: string): string | null => {
  if (!H) {
    return null;
  }

  let hex = H;
  const [firstChar] = hex;

  if (firstChar !== '#') {
    hex = `#${H}`;
  }

  // Convert hex to RGB first
  let red = 0;
  let green = 0;
  let blue = 0;

  const extractHexValue = (first: string, second: string): number => Number(`0x${first}${second}`);

  if (hex.length === 4) {
    red = extractHexValue(hex[1], hex[1]);
    green = extractHexValue(hex[2], hex[2]);
    blue = extractHexValue(hex[3], hex[3]);
  } else if (hex.length === 7) {
    red = extractHexValue(hex[1], hex[3]);
    green = extractHexValue(hex[3], hex[4]);
    blue = extractHexValue(hex[5], hex[6]);
  }
  // Then to HSL
  red /= 255;
  green /= 255;
  blue /= 255;

  const cmin = Math.min(red, green, blue);
  const cmax = Math.max(red, green, blue);
  const delta = cmax - cmin;
  let hue = 0;
  let saturation = 0;
  let lightness = 0;

  if (delta === 0) {
    hue = 0;
  } else if (cmax === red) {
    hue = ((green - blue) / delta) % 6;
  } else if (cmax === green) {
    hue = (blue - red) / delta + 2;
  } else {
    hue = (red - green) / delta + 4;
  }

  hue = Math.round(hue * 60);

  if (hue < 0) {
    hue += 360;
  }

  lightness = (cmax + cmin) / 2;
  saturation = delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));

  // Original script updated to stick to Figma results.
  saturation = +(Math.round(saturation * 100)); // Original : `saturation = +(saturation * 100).toFixed(1);`
  lightness = +(Math.round(lightness * 100)); // Original : `lightness = +(lightness * 100).toFixed(1);`

  return `${hue},${saturation}%,${lightness}%`;
};
