import { ELanguage } from '@/domain/core/Language.enum';
import { LegacyCategory, LegacyCategoryElement, LegacyCategoryElementI18n, MerchandisingItem } from '@/domain/product/types';

export const legacyCategoryAdapter = (category: LegacyCategoryElement, locale: string): LegacyCategory => {
  const translatedLegacyCategory: LegacyCategoryElementI18n = category?.i18n
    ?.find((translatedCategory) => translatedCategory.locale === locale) || {} as LegacyCategoryElementI18n;

  const parent = category?.parent ? legacyCategoryAdapter(category.parent, locale) : null;

  return {
    description: translatedLegacyCategory.description || null,
    editorialName: translatedLegacyCategory.editorial_name || null,
    i18nSlugs: extractI18nSlugs(category),
    id: category?.id || null,
    level: category?.level || null,
    locale: translatedLegacyCategory.locale || null,
    merchandisingItems: category?.merchandising_items || [],
    metaDescription: translatedLegacyCategory.meta_description || null,
    metaTitle: translatedLegacyCategory.meta_title || null,
    name: translatedLegacyCategory.name || null,
    parent,
    redirectionCategoryId: category?.redirection_category_id || null,
    slug: translatedLegacyCategory.slug || null,
  };
};

export const s3MerchItemAdapter = (s3MerchItem: { imageId: string; title: string; url: string }, locale: string): MerchandisingItem => ({
  image: s3MerchItem?.imageId,
  language: locale,
  link: s3MerchItem?.url,
  title: s3MerchItem?.title,
});

const extractI18nSlugs = (category: LegacyCategoryElement): Record<ELanguage, string> | null => {
  if (!category?.i18n?.length) {
    return null;
  }

  const i18nSlugs: Record<string, string> = {};

  for (const i18nCategoryElement of category.i18n) {
    i18nSlugs[i18nCategoryElement.language] = i18nCategoryElement.slug;
  }

  return i18nSlugs;
};
