
import Vue from 'vue';

import IcChevronRight from '@/assets/svg/ic_chevron_right.svg?inline';
import IcCrossSmall from '@/assets/svg/ic_cross_small.svg?inline';
import { ELabelledById } from '@/domain/core/a11y/LabelledById.enum';
import { SidebarComponent, SidebarComponentId } from '@/infrastructure/core/store/modules/Ui.interface';

enum ERefs {
  BaseSidebar = 'baseSidebar',
  BaseSidebarContent = 'baseSidebarContent',
}

export default Vue.extend({
  name: 'BaseSidebar',
  components: {
    IcChevronRight,
    IcCrossSmall,
  },
  data() {
    return {
      ELabelledById,
      ERefs,
      previousElement: null as HTMLElement | null, // Allow us to focus again the trigger button once the modal is closed.
    };
  },
  computed: {
    currentComponent(): SidebarComponent {
      return this.$accessor.ui.sidebar.currentComponent;
    },
    currentSidebarId(): SidebarComponentId | null {
      return this.currentComponent?.id || null;
    },
    previousComponent(): SidebarComponent {
      return this.$accessor.ui.sidebar.previousComponent;
    },
    isSidebarOpen(): boolean {
      return this.$accessor.ui.sidebar.isOpen;
    },
  },
  watch: {
    'currentComponent.component'(): void {
      this.scrollSidebarContentToTop();
    },
    isSidebarOpen(isOpen: boolean): void {
      if (isOpen) {
        this.handleSidebarAfterOpen();
      } else {
        this.handleSidebarAfterClose();
      }
    },
  },
  methods: {
    capturePreviousElement(element: HTMLElement | null): void {
      this.previousElement = element;
    },
    closeSidebar(): void {
      this.$accessor.ui.closeDialog();
    },
    focusElement(element?: HTMLElement | null): void {
      if (element) {
        element.focus();
      }
    },
    focusSidebar(): void {
      const sidebarElement = (this.$refs?.[ERefs.BaseSidebar] as HTMLElement);

      this.focusElement(sidebarElement);
    },
    focusPreviousElement(): void {
      this.focusElement(this.previousElement);
    },
    handleSidebarAfterClose(): void {
      this.focusPreviousElement();
      this.capturePreviousElement(null);
    },
    async handleSidebarAfterOpen(): Promise<void> {
      await this.$nextTick(); // BaseSidebar's ref does not exist right away.

      this.capturePreviousElement((document?.activeElement as HTMLElement) || document?.body);
      this.focusSidebar();
    },
    async scrollSidebarContentToTop(): Promise<void> {
      if (process.client) {
        const sidebarWrapperElement = this.$refs?.[ERefs.BaseSidebarContent] as HTMLElement;

        await this.$nextTick();

        if (sidebarWrapperElement) {
          sidebarWrapperElement.scrollTop = 0;
        }
      }
    },
  },
});
