var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:{
    'base-button': !_vm.isUnstyled,
    'base-button--disabled': _vm.isDisabled && !_vm.isUnstyled,
    'base-button--primary': _vm.isPrimary && !_vm.isUnstyled,
    'base-button--secondary': _vm.isSecondary && !_vm.isPrimary && !_vm.isUnstyled,
    'base-button--secondary-light': _vm.isSecondaryLight && !_vm.isSecondary && !_vm.isPrimary && !_vm.isUnstyled,
  },attrs:{"data-testid":"base-button","disabled":_vm.isDisabled || _vm.isLoading,"type":_vm.buttonType},on:{"click":_vm.handleClick}},[(!_vm.isUnstyled)?[_c('IcLoader',{staticClass:"absolute z-0 size-4 transition-opacity motion-reduce:transition-none animate-spin",class:{
        'opacity-100 delay-150': _vm.isLoading,
        'opacity-0': !_vm.isLoading,
      },attrs:{"aria-hidden":"true","data-testid":"base-button-loading"}}),_vm._v(" "),(_vm.isLoading)?_c('span',{staticClass:"sr-only",attrs:{"aria-busy":"true","aria-live":"polite","data-testid":"base-button-loading-aria"}},[_vm._v("\n      "+_vm._s(_vm.$t('a11y.loading'))+"\n    ")]):_vm._e()]:_vm._e(),_vm._v(" "),_c('span',{staticClass:"z-10 transition-opacity motion-reduce:transition-none",class:{
      'opacity-0': _vm.isLoading,
      'opacity-100 delay-150': !_vm.isLoading,
    },attrs:{"data-testid":"base-button-slot-wrapper"}},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }