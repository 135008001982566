
import Vue, { PropType } from 'vue';

import IcBell from '@/assets/svg/ic_bell.svg?inline';
import IcBellCrossed from '@/assets/svg/ic_bell_crossed.svg?inline';
import IcLoader from '@/assets/svg/ic_loader.svg?inline';
import { Search } from '@/domain/search/types';

export default Vue.extend({
  name: 'SearchSaved',
  components: {
    IcBell,
    IcBellCrossed,
    IcLoader,
  },
  props: {
    search: {
      required: true,
      type: Object as PropType<Search>,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isAlertActivated(): boolean {
      return this.search.shouldSendEmail || this.search.shouldSendNotification;
    },
    modelValue: {
      get(): boolean {
        this.setLoadingState(false);

        return this.isAlertActivated;
      },
      set(value: boolean): void {
        this.setLoadingState(true);

        this.$emit('update:value', value);
      },
    },
  },
  methods: {
    setLoadingState(state: boolean): void {
      this.isLoading = state;
    },
  },
});
