export enum ECurrencyCode {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
}

export interface CurrencyRate {
  code: string;
  rate: number;
}

export interface Price {
  amount: number;
  currency: `${ECurrencyCode}`;
}
