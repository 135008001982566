import { actionTree, mutationTree } from 'typed-vuex';

import { EFeatureFlags as MutationType } from '@/domain/core/store/MutationType.enum';
import { EFeatureFlags, FeatureFlagsState } from '@/infrastructure/externalServices/launchDarkly/types';
import { accessorType } from '@/store';

export const state = (): FeatureFlagsState => ({
  [EFeatureFlags.CheckoutPickupPointMap]: false,
  [EFeatureFlags.CraftmanshipHandmadeHomepageBanner]: false,
  [EFeatureFlags.CraftmanshipHandmadeItems]: false,
  [EFeatureFlags.CraftmanshipHandmadeProductPageMarketingMessage]: false,
  [EFeatureFlags.HelpBannerCustomerCare]: false,
  [EFeatureFlags.HomepageProBanner]: false,
  [EFeatureFlags.OneDayPayment]: false,
  [EFeatureFlags.ReviewsInternalizeCollection]: false,
  [EFeatureFlags.Sales]: false,
  [EFeatureFlags.SearchProductsWithinAutocompleteSuggestions]: false,
  [EFeatureFlags.SearchRecentSearchesSuggestions]: false,
  [EFeatureFlags.SeoCategoryPagesContent]: false,
});

export const mutations = mutationTree(state, {
  [MutationType.SET_FEATURE_FLAGS]: (state, payload: Partial<FeatureFlagsState>): void => {
    Object.assign(state, payload);
  },
});

export const actions = actionTree({ state, mutations }, {
  async getAllFeatureFlags(_): Promise<void> {
    const accessor: typeof accessorType = this.app.$accessor;

    try {
      const featureFlagStatuses = await this.$services.launchDarklyClient.getAllFeatureFlagStatuses(accessor.user?.email || null);

      accessor.featureFlags.SET_FEATURE_FLAGS(featureFlagStatuses);
    } catch (err) {
      this.$errorMonitor.report(err, 'error');
    }
  },
});
