import { Plugin } from '@nuxt/types';

import { AllCookies } from '@/domain/core/http/Cookie.enum';
import { EHeader } from '@/domain/core/http/Header';

const axiosPlugin: Plugin = ({ $axios, $errorMonitor, $cookies }): void => {
  const { language } = $cookies.getAll<AllCookies>();

  if (language) {
    $axios.defaults.headers.common[`${EHeader.AcceptLanguage}`] = language;
  }

  $axios.interceptors.response.use((response) => {
    const deprecationHeader = response?.headers?.[EHeader.Deprecation];

    if (deprecationHeader) {
      $errorMonitor.report(`
        API version for the following endpoint "${response?.config?.url}" is deprecated since "${deprecationHeader}".
      `, 'warning');
    }

    return response;
  });

  $axios.interceptors.response.use(
    ({ data, status, statusText }) => ({
      content: data,
      statusCode: status,
      statusMessage: statusText,
    }),
    (error) => {
      const { response } = error;

      const fmtError = {
        content: response?.data,
        statusCode: response?.status,
        statusMessage: response?.data?.message,
      };

      return Promise.reject(fmtError);
    },
  );
};

export default axiosPlugin;
