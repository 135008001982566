
import Vue from 'vue';

import Logo from '@/assets/svg/logo.svg?inline';

export default Vue.extend({
  components: {
    Logo,
  },
  layout: 'default',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasErrorCode(): boolean {
      return !!this.error?.statusCode;
    },
  },
  methods: {
    reloadPage(): void {
      window.location.reload();
    },
  },
});
