export enum EMetaTags {
  Author = 'author',
  Description = 'description',
  GoogleSiteVerification = 'google-site-verification',
  PinterestKey = 'p:domain_verify',
  Robots = 'robots',
  ThemeColor = 'theme-color',
  Title = 'title',
  Viewport = 'viewport',
}

export enum ETwitter {
  Card = 'twitter:card',
  Description = 'twitter:description',
  Image = 'twitter:image',
  Site = 'twitter:site',
  Title = 'twitter:title',
}

export enum EOpenGraph {
  Description = 'og:description',
  Image = 'og:image',
  SiteName = 'og:site_name',
  Title = 'og:title',
  Type = 'og:type',
  Url = 'og:url',
}

export enum ELinkType {
  Alternate = 'alternate',
  Canonical = 'canonical',
  Preconnect = 'preconnect',
  Stylesheet = 'stylesheet',
}

export enum ECrossOriginAttributes {
  Anonymous = 'anonymous',
  UseCredentials = 'use-credentials',
}
