
import Vue from 'vue';

import { ERouteName } from '@/domain/core/Routes.enum';
import { NavigationStepLink } from '@/infrastructure/core/components/navigation/NavigationStepLink.interface';

export default Vue.extend({
  name: 'NavigationStepsCheckout',
  data() {
    const baseI18nPath = 'page.checkout.navigation';

    return {
      steps: [
        {
          icon: () => import('@/assets/svg/ic_cart.svg?inline'),
          ids: [ERouteName.CheckoutCart],
          label: this.$t(`${baseI18nPath}.${ERouteName.CheckoutCart}.title`),
          href: this.$router.resolve({ name: ERouteName.CheckoutCart }).href,
        },
        {
          icon: () => import('@/assets/svg/ic_truck.svg?inline'),
          ids: [ERouteName.CheckoutDelivery, ERouteName.CheckoutPayment],
          label: this.$t('page.checkout.order'),
        },
        // {
        //   icon: () => import('@/assets/svg/ic_credit_card.svg?inline'),
        //   id: ERouteName.CheckoutPayment,
        //   label: this.$t(`${baseI18nPath}.${ERouteName.CheckoutPayment}.title`),
        //   href: this.$router.resolve({ name: ERouteName.CheckoutPayment }).href,
        // },
        {
          ids: [ERouteName.CheckoutSuccess],
        },
      ] as NavigationStepLink[],
    };
  },
  computed: {
    currentRouteName(): string {
      return this.$route?.name || '';
    },
    currentStepIndex(): number {
      return this.steps.findIndex(({ ids }) => ids.includes(this.currentRouteName));
    },
    isLastStep(): boolean {
      return this.currentStepIndex === this.steps.length - 1;
    },
  },
});
