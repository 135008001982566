
import Vue, { PropType } from 'vue';

import { Search } from '@/domain/search/types';

export default Vue.extend({
  name: 'SearchList',
  props: {
    searches: {
      required: true,
      type: Array as PropType<Search[]>,
    },
    shouldDisplayPlaceholder: {
      type: Boolean,
      required: true,
    },
    maxDisplayedSearches: {
      type: Number,
      default: 3,
    },
  },
});
