import { Middleware } from '@nuxt/types';

import { ERouteName } from '@/domain/core/Routes.enum';

const authenticated: Middleware = ({ $accessor, redirect }) => {
  if (!$accessor.user.authenticated) {
    return redirect({ name: ERouteName.Homepage });
  }
};

export default authenticated;
