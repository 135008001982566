import { Plugin } from '@nuxt/types';

import { StoreTokenProvider } from '@/services/security.service';

const securityPlugin: Plugin = ({ $accessor }, inject) => {
  const security = new StoreTokenProvider($accessor);

  inject('security', security);
};

export default securityPlugin;
