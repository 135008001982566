
import Vue from 'vue';

import IcStar from '@/assets/svg/ic_star.svg?inline';
import IcStarHalf from '@/assets/svg/ic_star_half.svg?inline';
import IcStarWireframe from '@/assets/svg/ic_star_wireframe.svg?inline';
import IcStarWireframeEmpty from '@/assets/svg/ic_star_wireframe_empty.svg?inline';
import IcStarWireframeHalf from '@/assets/svg/ic_star_wireframe_half.svg?inline';

const starsMaxCount = 5;

export default Vue.extend({
  name: 'ProductRatingStars',
  components: {
    IcStar,
    IcStarHalf,
    IcStarWireframe,
    IcStarWireframeEmpty,
    IcStarWireframeHalf,
  },
  props: {
    isWireframe: {
      type: Boolean,
      default: false,
    },
    rating: {
      type: Number,
      default: starsMaxCount,
    },
    starSizeClasses: {
      type: String,
      default: 'size-3',
    },
  },
  computed: {
    component(): { default: string; empty: string; half: string } {
      let component = {
        default: 'IcStar',
        half: 'IcStarHalf',
        empty: 'IcStar',
      };

      if (this.isWireframe) {
        component = {
          default: 'IcStarWireframe',
          half: 'IcStarWireframeHalf',
          empty: 'IcStarWireframeEmpty',
        };
      }

      return component;
    },
    safeRating(): number {
      const starsMinCount = 0;
      let grade = starsMaxCount;

      if (this.rating >= starsMinCount && this.rating <= starsMaxCount) {
        grade = this.rating;
      }

      return grade;
    },
    emptyStarCount(): number {
      return Math.floor(starsMaxCount - this.safeRating);
    },
    fullStarCount(): number {
      return Math.floor(this.safeRating);
    },
    hasHalfStar(): boolean {
      return Number(this.safeRating) === this.safeRating && this.safeRating % 1 !== 0;
    },
  },
});
