import { legacyWishlistAdapter, legacyWishlistBoardAdapter } from '@/adapters/wishlist.adapter';
import { ApiLegacyBase } from '@/domain/core/api/ApiLegacyBase.interface';
import { EHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import { UserPrivate } from '@/domain/user/types';
import {
  AddProductToWishlistPayload,
  LegacyWishlist,
  LegacyWishlistBoard,
  RemoveProductFromWishlistPayload,
  Wishlist,
  WishlistBoard,
  WishlistProduct,
} from '@/domain/wishlist/types';
import { WishlistRepositoryInterface } from '@/domain/wishlist/wishlistRepository.interface';
import HttpService from '@/services/http.service';

const resourcePath = '/wishlists';

export default class WishlistRepository implements WishlistRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;
  readonly #security: SecurityTokenProvider;

  constructor(httpService: HttpService, basePath: string, security: SecurityTokenProvider) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#security = security;
  }

  async addProduct({ boardId, productId, wishlistId }: AddProductToWishlistPayload): Promise<Wishlist> {
    const token = this.#security.getToken();
    let payload: {
      boardId?: WishlistBoard['id'];
      mv?: boolean;
      product: WishlistProduct['id'];
    } = { product: productId };

    if (boardId) {
      payload = {
        ...payload,
        boardId,
        mv: true,
      };
    }

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlist>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Put,
      path: `${this.#basePath}${resourcePath}/${wishlistId}/products`,
      payload,
    });

    return legacyWishlistAdapter(content.content);
  }

  async createBoard({ boardName, wishlistId }: {
    boardName: WishlistBoard['name'];
    wishlistId: Wishlist['id'];
  }): Promise<Wishlist> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlist>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Put,
      path: `${this.#basePath}${resourcePath}/${wishlistId}/boards`,
      payload: {
        board_name: boardName,
      },
    });

    return legacyWishlistAdapter(content.content);
  }

  async deleteBoard({ boardId, wishlistId }: {
    boardId: WishlistBoard['id'];
    wishlistId: Wishlist['id'];
  }): Promise<Wishlist> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlist>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Delete,
      path: `${this.#basePath}${resourcePath}/${wishlistId}/boards`,
      queryParams: { boardId },
    });

    return legacyWishlistAdapter(content.content);
  }

  async createWishlist(): Promise<Wishlist> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlist>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Post,
      path: `${this.#basePath}${resourcePath}`,
    });

    return legacyWishlistAdapter(content.content);
  }

  async getBoard({ boardId, wishlistId }: {
    boardId: WishlistBoard['id'];
    wishlistId: Wishlist['id'];
  }): Promise<WishlistBoard> {
    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlistBoard>>({
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}/${wishlistId}/boards/${boardId}`,
    });

    return legacyWishlistBoardAdapter(content?.content);
  }

  async getWishlistByUserId(userId: UserPrivate['legacyId']): Promise<Wishlist> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlist>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}`,
      queryParams: { user: `${userId}` },
    });

    return legacyWishlistAdapter(content.content);
  }

  async removeProduct({ productId, wishlistId }: RemoveProductFromWishlistPayload): Promise<Wishlist> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyWishlist>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Delete,
      path: `${this.#basePath}${resourcePath}/${wishlistId}/products`,
      queryParams: { product: productId },
    });

    return legacyWishlistAdapter(content.content);
  }
}
