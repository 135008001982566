import { ELanguage } from '@/domain/core/Language.enum';

export enum EOfferStatus {
  Accepted = 'OFFER_STATUS_ACCEPTED',
  Canceled = 'OFFER_STATUS_CANCELED',
  DelayAccepted = 'OFFER_STATUS_DELAY_ACCEPTED',
  Expired = 'OFFER_STATUS_EXPIRED',
  Pending = 'OFFER_STATUS_PENDING',
  Purchased = 'OFFER_STATUS_PURCHASED',
  Rejected = 'OFFER_STATUS_REJECTED',
}

export enum EThreadType {
  Banned = 'private-banned',
  Expertize = 'expertize', // Not used anymore
  Private = 'private',
  Product = 'product', // Not used anymore
  Unauthorized = 'unauthorized',
}

export enum EMessageStatus {
  Draft = 'MESSAGE_STATUS_DRAFT',
  Hidden = 'MESSAGE_STATUS_HIDDEN',
  Pending = 'MESSAGE_STATUS_PENDING',
  Published = 'MESSAGE_STATUS_PUBLISHED',
  Rejected = 'MESSAGE_STATUS_REJECTED',
  Validated = 'MESSAGE_STATUS_VALIDATED',
}

export enum EMessageType {
  Message = 'message',
  Offer = 'offer',
}
export interface LegacyMessagePayload {
  authorName: string;
  body: string;
  client: string | null;
  locale: string;
  original: string | null;
  product: string;
  userId: string;
}

export interface LegacyOfferPayload {
  authorName: string;
  client: string | null;
  locale: string;
  offer: {
    price: {
      currency: string;
      value: number;
    };
  };
  original: string | null;
  product: string;
  userId: string;
}

export interface LegacyMessage {
  author_name: string | null;
  body: string | null;
  config: {
    last_user_type: string;
    price: number;
    readed: boolean;
    status: `${EOfferStatus}`;
    status_at: string;
    uuid: string | null;
  };
  created_at: string;
  created_by: {
    locale: `${ELanguage}`;
    user_id: string;
  };
  i18n: {
    body: string | null;
    locale: `${ELanguage}`;
  }[];
  id: string;
  level: number | null;
  parent: LegacyMessage | null;
  path: string;
  status: `${EMessageStatus}`;
  thread: {
    client: {
      locale: string;
      user_id: string;
    };
    config: {
      client: {
        last_message: string | null;
        last_rejected_message: string | null;
        name: string | null;
        rejected: number;
        unread: number;
      };
      offer?: {
        price: number | null;
        status: `${EOfferStatus}`;
        uuid: string | null;
      };
      seller: {
        last_message: string;
        last_rejected_message: null;
        name: string;
        rejected: number;
        unread: number;
      };
    };
    created_at: string;
    created_by: {
      locale: string;
      user_id: string;
    };
    id: string | null;
    last_customer_message_date: string;
    messages: string[] | null;
    order_product: null;
    original: string;
    product: string | null;
    type: `${EThreadType}`;
    updated_at: string | null;
  };
  type: `${EMessageType}`;
  updated_at: null;
}
