import { NuxtCookies } from 'cookie-universal-nuxt';
import aa from 'search-insights';

import { ESearchInsightEvent, ESearchInsightEventType } from '@/domain/core/algolia/types';
import { ECookie } from '@/domain/core/http/Cookie.enum';
import { Product, ProductCore } from '@/domain/product/types';
import { internationalizeIndexName } from '@/infrastructure/externalServices/algolia/internationalizeIndexName';

export default class AlgoliaSearchInsights {
  readonly #productsIndexName: string;
  readonly #i18nLocale: string;
  readonly #userId: string;
  readonly #cookies: NuxtCookies;

  constructor(
    appId: string,
    apiKey: string,
    productsIndexName: string,
    userId: string,
    cookies: NuxtCookies,
    i18nLocale: string,
  ) {
    this.#productsIndexName = productsIndexName;
    this.#i18nLocale = i18nLocale;
    this.#userId = userId;
    this.#cookies = cookies;

    aa('init', {
      appId,
      apiKey,
      useCookie: true,
    });

    this.#refreshAnalyticsUserToken();
  }

  sendClickEvent(eventName: ESearchInsightEvent, product: ProductCore): void {
    this.#refreshAnalyticsUserToken();

    aa(ESearchInsightEventType.Click, {
      eventName,
      index: internationalizeIndexName(this.#productsIndexName, this.#i18nLocale),
      objectIDs: [product.objectID],
      positions: [product.__position],
      queryID: product.__queryID,
    });
  }

  sendConversionEvent(eventName: ESearchInsightEvent, product: Product): void {
    this.#refreshAnalyticsUserToken();

    aa(ESearchInsightEventType.Conversion, {
      eventName,
      index: internationalizeIndexName(this.#productsIndexName, this.#i18nLocale),
      queryID: product.__queryID,
      objectIDs: [product.objectID || product.id],
    });
  }

  #refreshAnalyticsUserToken(): void {
    aa('setUserToken', this.#userId || this.#cookies.get(ECookie.UserSegmentId));
  }
}
