
import Vue from 'vue';

export default Vue.extend({
  name: 'BadgeDiscount',
  props: {
    cssClasses: {
      type: String,
      default: '',
    },
    isUnstyled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
  },
});
