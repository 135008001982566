import locale275327d8 from '../../lang/fr.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"fr"},
  vueI18nLoader: false,
  locales: [{"code":"fr","file":"fr.json","iso":"fr-FR"},{"code":"en","file":"en.json","iso":"en-GB"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"language","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "https://www.selency.fr",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"fr","file":"fr.json","iso":"fr-FR"},{"code":"en","file":"en.json","iso":"en-GB"}],
  localeCodes: ["fr","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "f",
  3: "r",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "l",
  16: "a",
  17: "n",
  18: "g",
  19: "/",
  20: "f",
  21: "r",
  22: ".",
  23: "j",
  24: "s",
  25: "o",
  26: "n",
  27: "\"",
  28: ",",
  29: "\"",
  30: "e",
  31: "n",
  32: ".",
  33: "j",
  34: "s",
  35: "o",
  36: "n",
  37: "\"",
  38: ":",
  39: "\"",
  40: ".",
  41: ".",
  42: "/",
  43: "l",
  44: "a",
  45: "n",
  46: "g",
  47: "/",
  48: "e",
  49: "n",
  50: ".",
  51: "j",
  52: "s",
  53: "o",
  54: "n",
  55: "\"",
  56: "}",
}

export const localeMessages = {
  'fr.json': () => Promise.resolve(locale275327d8),
  'en.json': () => import('../../lang/en.json' /* webpackChunkName: "lang-en.json" */),
}
