import { CartRepositoryInterface } from '@/domain/cart/cartRepository.interface';
import {
  EDeliveryService,
  LegacyAddProductToCartPayload,
  LegacyCart,
  LegacyCartProductInfo,
  LegacyCartProductUpdatePayload,
  LegacyCartUpdatePayload,
  LegacyCoupon,
} from '@/domain/cart/types';
import { ApiLegacyBase } from '@/domain/core/api/ApiLegacyBase.interface';
import { EContentType } from '@/domain/core/ContentType.enum';
import { EHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import { Headers } from '@/infrastructure/core/http/HttpRequest.interface';
import HttpService from '@/services/http.service';
import { ExternalURLRouterInterface } from '@/services/interfaces/externalURLRouter.interface';

const domainPath = '/carts';

export default class CartRepository implements CartRepositoryInterface {
  readonly #basePath: string;
  readonly #externalURLRouter: ExternalURLRouterInterface;
  readonly #httpService: HttpService;
  readonly #legacyBasePath: string;
  readonly #security: SecurityTokenProvider;

  constructor(
    httpService: HttpService,
    basePath: string,
    legacyBasePath: string,
    security: SecurityTokenProvider,
    externalURLRouter: ExternalURLRouterInterface,
  ) {
    this.#basePath = basePath;
    this.#externalURLRouter = externalURLRouter;
    this.#httpService = httpService;
    this.#legacyBasePath = legacyBasePath;
    this.#security = security;
  }

  async addDeliveryService(id: LegacyCart['id'], deliveryServiceName: EDeliveryService): Promise<void> {
    const token = this.#security.getToken();

    await this.#httpService.request<ApiLegacyBase<LegacyCart>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Post,
      path: `${this.#legacyBasePath}${domainPath}/${id}/delivery-services`,
      payload: {
        deliveryServiceName,
      },
    });
  }

  async addProduct(id: LegacyCart['id'], payload: LegacyAddProductToCartPayload): Promise<LegacyCart> {
    const headers = this.#getAuthorizationHeaders(); // NOTE: auth token isn't required if the cart is anonymous.

    const { content } = await this.#httpService.request<LegacyCart>({
      headers,
      method: EHttpMethod.Post,
      path: `${this.#legacyBasePath}${domainPath}/${id}/products`,
      payload: {
        productId: payload.productId,
        quantity: payload.quantity,
      },
    });

    return content;
  }

  async addProductLegacy(payload: LegacyAddProductToCartPayload): Promise<LegacyCart> {
    const formData = new FormData();

    formData.append('cart_add_product[productId]', payload.productId);
    formData.append('cart_add_product[quantity]', `${payload.quantity}`);
    formData.append('cart_add_product[deliveryOfferId]', `${payload.deliveryOfferId}`);

    const addToCartPath = this.#externalURLRouter.getPathFromRoute('add-to-cart');

    if (!addToCartPath) {
      throw new Error('Add to cart path could not be found');
    }

    const { content } = await this.#httpService.request<LegacyCart>({
      method: EHttpMethod.Post,
      path: addToCartPath,
      payload: formData,
      queryParams: {
        shouldRedirect: 'false',
      },
    });

    return content;
  }

  async applyVoucher(id: LegacyCart['id'], voucher: string): Promise<LegacyCoupon> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyCoupon>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Post,
      path: `${this.#legacyBasePath}${domainPath}/${id}/coupons`,
      payload: {
        couponCode: voucher,
      },
    });

    return content?.content;
  }

  async create(): Promise<LegacyCart> {
    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyCart>>({
      method: EHttpMethod.Post,
      path: `${this.#legacyBasePath}${domainPath}`,
    });

    return content?.content;
  }

  async delete(id: LegacyCart['id']): Promise<void> {
    const token = this.#security.getToken();

    if (!id) {
      throw new Error('No cart ID provided');
    }

    await this.#httpService.request<ApiLegacyBase<LegacyCart>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Delete,
      path: `${this.#basePath}${domainPath}/${id}`,
    });
  }

  async get(id: LegacyCart['id']): Promise<LegacyCart> {
    if (!id) {
      throw new Error('No cart ID provided');
    }

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyCart>>({
      method: EHttpMethod.Get,
      path: `${this.#legacyBasePath}${domainPath}/${id}`,
    });
    const cart = content?.content;

    if (!cart) {
      throw new Error('No cart data retrieved');
    }

    return cart;
  }

  async getByAuthToken(): Promise<LegacyCart> {
    // NOTE: the auth token isn't required if the cart is anonymous.
    const headers = this.#getAuthorizationHeaders();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyCart>>({
      headers,
      method: EHttpMethod.Get,
      path: `${this.#legacyBasePath}${domainPath}/current`,
    });

    return content?.content;
  }

  async getQuote(id: LegacyCart['id']): Promise<Blob> {
    if (!id) {
      throw new Error('No cart ID provided');
    }

    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<Blob>({
      headers: {
        [EHeader.Accept]: [EContentType.MsExcel, EContentType.Json].join(', '),
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${domainPath}/${id}/quotation`,
      responseType: 'blob',
    });

    return content;
  }

  async merge(anonymousCartId: LegacyCart['id']): Promise<LegacyCart> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyCart>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Put,
      path: `${this.#legacyBasePath}${domainPath}/current`,
      payload: {
        mergeFrom: anonymousCartId,
      },
    });

    return content?.content;
  }

  async removeDeliveryService(id: LegacyCart['id'], deliveryServiceName: EDeliveryService): Promise<void> {
    const token = this.#security.getToken();

    await this.#httpService.request<ApiLegacyBase<LegacyCart>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Delete,
      path: `${this.#legacyBasePath}${domainPath}/${id}/delivery-services/${deliveryServiceName}`,
    });
  }

  async removeProduct(id: LegacyCart['id'], cartProductId: LegacyCartProductInfo['id']): Promise<void> {
    if (!id) {
      throw new Error('No cart ID provided');
    }

    await this.#httpService.request<LegacyCart>({
      method: EHttpMethod.Delete,
      path: `${this.#legacyBasePath}/cart-products/${cartProductId}`,
      queryParams: {
        cartId: id,
      },
    });
  }

  async update(id: LegacyCart['id'], payload: LegacyCartUpdatePayload): Promise<void> {
    await this.#httpService.request<LegacyCart>({
      method: EHttpMethod.Put,
      path: `${this.#legacyBasePath}${domainPath}/${id}`,
      payload,
    });
  }

  async updateProduct(
    id: LegacyCart['id'],
    cartProductId: LegacyCartProductInfo['id'],
    payload: LegacyCartProductUpdatePayload,
  ): Promise<void> {
    await this.#httpService.request<LegacyCart>({
      method: EHttpMethod.Put,
      path: `${this.#legacyBasePath}/cart-products/${cartProductId}`,
      payload,
      queryParams: {
        cartId: id,
      },
    });
  }

  #getAuthorizationHeaders(): Pick<Headers, 'authorization'> {
    let headers: Pick<Headers, 'authorization'> = {};

    try {
      const token = this.#security.getToken();

      if (token) {
        headers = {
          [EHeader.Authorization]: `Bearer ${token}`,
        };
      }
    } catch {} // Shhhh... It's OK, it's OK... Shhhh... There, fail silently... 🤫

    return headers;
  }
}
