import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { ApiItemBase } from '@/domain/core/api/ApiItemBase.interface';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import { SearchRepositoryInterface } from '@/domain/search/searchRepository.interface';
import { AlertParameters, SaveSearchPayload, Search } from '@/domain/search/types';
import { transform, transformList } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

export default class SearchRepository implements SearchRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;
  readonly #security: SecurityTokenProvider;

  constructor(httpService: HttpService, basePath: string, security: SecurityTokenProvider) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#security = security;
  }

  async getList(userId: string, limit: number | null = null, isAlertActivated: boolean = false): Promise<Search[]> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiBase<Search>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}/users/${userId}/searches`,
      queryParams: { limit, isAlertActivated },
    });

    return transformList(content?.items);
  }

  async save(userId: string, payload: SaveSearchPayload): Promise<Search> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiItemBase<Search>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Post,
      path: `${this.#basePath}/users/${userId}/searches`,
      payload,
    });

    const search = transform(content);

    if (!search?.searchId) {
      throw new Error('No search returned on search save');
    }

    return search;
  }

  async update(searchId: string, payload: AlertParameters): Promise<Search> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiItemBase<Search>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Put,
      path: `${this.#basePath}/searches/${searchId}`,
      payload,
    });

    return transform(content);
  }

  async delete(searchId: string): Promise<void> {
    const token = this.#security.getToken();

    await this.#httpService.request<ApiItemBase<Search>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Delete,
      path: `${this.#basePath}/searches/${searchId}`,
    });
  }
}
