import Vue from 'vue';
import { NumberFormatOptions } from 'vue-i18n';

import { ECookie, ECookieMaxAge } from '@/domain/core/http/Cookie.enum';
import { ELanguage, LanguageISO } from '@/domain/core/Language.enum';
import { ECurrencyCode } from '@/domain/pricing/types';
import { CurrencyCode } from '@/infrastructure/core/store/Currency.interface';

export default Vue.extend({
  computed: {
    $_currency_onlySupportedLocale(): string {
      return this.$i18n.locale === ELanguage.FR && this.$_currency_selectedCurrencyCode === ECurrencyCode.EUR
        ? LanguageISO.FR.FR
        : LanguageISO.EN.US;
    },
    $_currency_i18nNumberFormatOptions(): NumberFormatOptions {
      return {
        currency: this.$_currency_selectedCurrencyCode,
        currencyDisplay: 'symbol',
        style: 'currency',
      };
    },
    $_currency_selectedCurrencyCode: {
      get(): CurrencyCode {
        return this.$accessor.pricing.selectedCurrencyCode;
      },
      set(newCode: CurrencyCode): void {
        this.$accessor.pricing.setCurrencyCode(newCode);
        this.$cookies.set(ECookie.Currency, newCode, {
          maxAge: ECookieMaxAge.MaxAllowedByGDPR,
          path: '/',
          sameSite: 'lax',
        });
      },
    },
  },
  methods: {
    // WARNING: Safari does not support { currencyDisplay: 'narrowSymbol' } but accepts 'symbol'.
    // By using { currencyDisplay: 'symbol' } coupled with a locale set to 'en-US' we're able to mimic its behavior.
    // TODO: use a polyfill for Intl.NumberFormatPart to be able to use narrowSymbol.
    $_currency_numberFormatToParts(amount: number, options?: NumberFormatOptions): Intl.NumberFormatPart[] {
      const formatOptions = {
        ...this.$_currency_i18nNumberFormatOptions,
        ...options,
      };

      return new Intl.NumberFormat(this.$_currency_onlySupportedLocale, formatOptions).formatToParts(amount);
    },
  },
});
