import { AddressRepositoryInterface } from '@/domain/address/addressRepository.interface';
import { Address, Country, LegacyCreateAddressPayload, LegacyCreateAddressResponse, PlaceAutocompleted, PlaceDetailed } from '@/domain/address/types';
import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { ApiItemBase } from '@/domain/core/api/ApiItemBase.interface';
import { ApiLegacyBase } from '@/domain/core/api/ApiLegacyBase.interface';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import { transform, transformList } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

const domainPath = '/addresses';

export default class AddressRepository implements AddressRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;
  readonly #legacyBasePath: string;
  readonly #security: SecurityTokenProvider;

  constructor(httpService: HttpService, basePath: string, legacyBasePath: string, security: SecurityTokenProvider) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#legacyBasePath = legacyBasePath;
    this.#security = security;
  }

  async getAddressDetails(id: Address['id']): Promise<Address> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiItemBase<Address>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${domainPath}/${id}`,
    });

    return transform(content);
  }

  async getCountries(): Promise<Country[]> {
    const { content } = await this.#httpService.request<ApiBase<Country>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${domainPath}/countries`,
    });

    return transformList(content?.items);
  }

  async getCurrentCountry(): Promise<Country> {
    const { content } = await this.#httpService.request<ApiItemBase<Country>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${domainPath}/current-country`,
    });

    return transform(content);
  }

  async createAddress(payload: LegacyCreateAddressPayload): Promise<LegacyCreateAddressResponse> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyCreateAddressResponse>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V1,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Post,
      path: `${this.#legacyBasePath}${domainPath}`,
      payload,
    });

    if (!content?.content) {
      throw new Error('No address data retrieved');
    }

    return content.content;
  }

  async searchPlaces(query: string): Promise<PlaceAutocompleted[]> {
    const maxPlacesAllowed = 3;

    const { content } = await this.#httpService.request<ApiBase<PlaceAutocompleted>>({
      method: EHttpMethod.Get,
      path: `${this.#basePath}/places`,
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      queryParams: {
        query,
        limit: maxPlacesAllowed,
      },
    });

    return content?.items?.map(({ item }) => item) || [];
  }

  async getPlaceDetails(placeId: string): Promise<PlaceDetailed> {
    const { content } = await this.#httpService.request<ApiItemBase<PlaceDetailed>>({
      method: EHttpMethod.Get,
      path: `${this.#basePath}/places/${placeId}`,
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
    });

    return content?.item;
  }
}
