import { actionTree, mutationTree } from 'typed-vuex';

import { ENotifications as MutationType } from '@/domain/core/store/MutationType.enum';
import {
  CartNotificationCount,
  MessagesNotificationCount,
  ProductsNotificationCount,
} from '@/infrastructure/core/service/data/NotificationData.interface';
import { NotificationsState } from '@/infrastructure/core/store/modules/Notifications.interface';
import { accessorType } from '@/store';

export const state = (): NotificationsState => ({
  cart: {
    itemsCount: 0,
  },
  messages: {
    unreadThreadsCount: 0,
  },
  products: {
    pendingEditsCount: 0,
  },
});

export const mutations = mutationTree(state, {
  [MutationType.SET_CART_COUNT]: (state, payload: CartNotificationCount): void => {
    state.cart = payload;
  },
  [MutationType.SET_MESSAGES_COUNT]: (state, payload: MessagesNotificationCount): void => {
    state.messages = payload;
  },
  [MutationType.SET_PRODUCTS_COUNT]: (state, payload: ProductsNotificationCount): void => {
    state.products = payload;
  },
});

export const actions = actionTree({ state, mutations }, {
  async fetchNotificationsCount(_, { cartId, userId }: {
    cartId: string | null;
    userId: string;
  }): Promise<void> {
    const accessor: typeof accessorType = this.app.$accessor;
    const { cart, messages, products } = await this.$services.notificationDataService.getNotifications({ cartId, userId });

    accessor.notifications.SET_CART_COUNT(cart);
    accessor.notifications.SET_MESSAGES_COUNT(messages);
    accessor.notifications.SET_PRODUCTS_COUNT(products);
  },
});
