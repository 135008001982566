
import Vue from 'vue';

import { EGtmEventHeader } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

export default Vue.extend({
  name: 'NavigationMainLink',
  props: {
    cssSpacingClasses: {
      type: String,
      default: 'py-1',
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    isPromo: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleFocus($event: Event): void {
      this.$emit('focus', $event);
    },
    handleClick(): void {
      this.$gtm.push({
        event: EGtmEventHeader.ClickLinkMainNav,
        name: this.value,
        url: this.url,
      });
    },
  },
});
