import { Country } from '@/domain/address/types';
import { EJobType, EUserType } from '@/domain/auth/types';
import { UserPublic } from '@/domain/user/types';

export enum EBadgeSellerLevel {
  Pro = 'professional',
  ProAmbassador = 'proAmbassador',
  SuperPro = 'superPro',
}

export type EBadgeSellerLevelType = `${EBadgeSellerLevel}` | null;

export enum EShopType {
  Individual = 'private_person',
  Professional = 'professional',
}

enum ECommissionType {
  All = 'all',
  Listing = 'listing',
  Product = 'product',
  Shop = 'shop',
  Sold = 'sold',
}

interface Commission {
  description: string;
  finishedAt: string;
  id: string;
  includingTaxes: number;
  isRetail: boolean;
  isSpecialOperation: boolean;
  label: string;
  locale: string;
  platform: string;
  shopType:`${EShopType}`;
  startedAt: string;
  type: `${ECommissionType}`;
  value: number;
}

export interface ShopStats {
  badgeLevel: 10 | 20 | 30 | null;
  daysOpeningShopTime: number;
  display: boolean;
  id: string;
  productsInSale: number;
  productsSold: number;
  rating: number | null;
  totalReviews: number;
}

export interface ShopDetails {
  activity: `${EUserType}` | null;
  businessId: string | null;
  commissions?: Commission[];
  country: Country['code'];
  id: string;
  isInternational: boolean;
  job: `${EJobType}` | null;
  legacyId: string;
  statistics?: ShopStats; // Embed
  type: `${EShopType}`;
  user?: UserPublic; // Embed
  userId: UserPublic['id'];
}

export interface ShopStatus {
  badgeLevel: ShopStats['badgeLevel'];
  id: ShopDetails['id'];
  type: ShopDetails['type'];
}
