import { EditoRepositoryInterface } from '@/domain/edito/editoRepository.interface';
import { HeaderData, HeaderDataMenuItem, HeaderDataMenuItemRequest } from '@/infrastructure/core/service/data/HeaderData.interface';
import { _isCurrentDateBetweenTwoDates } from '@/utilities/dates/isCurrentDateBetweenTwoDates';

export class HeaderDataService {
  readonly #editoRepository: EditoRepositoryInterface;
  readonly #i18nLocale: string;

  constructor(editoRepository: EditoRepositoryInterface, i18nLocale: string) {
    this.#editoRepository = editoRepository;
    this.#i18nLocale = i18nLocale;
  }

  async getHeaderData(): Promise<HeaderData> {
    const [
      header,
      menus,
    ] = await Promise.all([
      this.#editoRepository.getHeader(),
      this.#editoRepository.getMenu(),
    ]);

    const headerElements = header?.listing?.elements;

    const topBlock = headerElements?.topBlock;
    const colorTheme = headerElements?.colorTheme;
    const promoBlocks = headerElements?.promoBlock;

    const isColorThemeActive = colorTheme?.active
      && colorTheme.locale === this.#i18nLocale
      && _isCurrentDateBetweenTwoDates({ start: colorTheme.startingAt, end: colorTheme.endingAt });

    const menuTree = menus?.[0]?.tree;
    const parsedMenuTree: HeaderDataMenuItem[] = JSON.parse(menuTree);
    const menu: HeaderDataMenuItem[] = parsedMenuTree
      .map(({ id, new: isNew, promo: isPromo, ...item }: HeaderDataMenuItemRequest): HeaderDataMenuItem => {
        const itemId = Number.parseInt(id, 10);
        const fmtItem: HeaderDataMenuItem = {
          ...item,
          isNew,
          isPromo,
        };

        if (!Number.isNaN(itemId)) {
          fmtItem.promoBlocks = promoBlocks
            .filter((block) => Number.parseInt(block.categoryId, 10) === itemId)
            .sort((a, b) => a.position.localeCompare(b.position));
        }

        return fmtItem;
      });

    return {
      bannerTop: {
        content: topBlock?.content,
        color: topBlock?.color,
        fontColor: topBlock?.fontColor,
      },
      accentColor: {
        color: colorTheme?.color,
        fontColor: colorTheme?.fontColor,
        isActive: isColorThemeActive,
      },
      menu,
    };
  }
}
