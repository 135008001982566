
import Vue, { PropType } from 'vue';

import { QuerySuggestionDocument } from '@/domain/core/algolia/types';
import { ERouteName } from '@/domain/core/Routes.enum';
import { EGtmEventSearch } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

export default Vue.extend({
  name: 'InstantSearchSuggestions',
  props: {
    suggestions: {
      type: Array as PropType<QuerySuggestionDocument[]>,
      required: true,
    },
  },
  methods: {
    buildUrlFromHit({ pageType, query, url }: QuerySuggestionDocument): string {
      const regularPageTypes = ['category', 'facets', 'search'];

      if (regularPageTypes.includes(pageType)) {
        return url;
      }

      return this.$router.resolve({
        name: ERouteName.Search,
        query: { query },
      }).href;
    },
    handleClick(suggestion: QuerySuggestionDocument): void {
      this.$emit('click-suggestion', suggestion.query);
      this.handleTracking(suggestion.query, this.buildUrlFromHit(suggestion));
    },
    handleTracking(query: string, url: string): void {
      this.$gtm.push({
        event: EGtmEventSearch.ClickSuggestion,
        search_term: query,
        url: `${window.location.origin}${url}`,
      });
    },
  },
});
