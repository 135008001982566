export enum ESocialType {
  Facebook = 'Facebook',
  Houzz = 'Houzz',
  Instagram = 'Instagram',
  Pinterest = 'Pinterest',
  Twitter = 'Twitter',
}

export enum ESocialUrl {
  Facebook = 'https://www.facebook.com/selency',
  Houzz = 'https://www.houzz.fr/pro/selency',
  Instagram = 'https://www.instagram.com/selency',
  Pinterest = 'https://www.pinterest.fr/selency',
  Twitter = 'https://twitter.com/selency_com',
}
