import { SearchResponse } from '@algolia/client-search';
import { SearchClient, SearchIndex } from 'algoliasearch/lite';

import { algoliaProductAdapter } from '@/adapters/product.adapter';
import {
  EFacetNameRefinementList as EFacetName,
  EFacetNameToggleRefinement as EFacetNameToggle,
  QuerySuggestionDocumentSet,
} from '@/domain/core/algolia/types';
import { ELanguage } from '@/domain/core/Language.enum';
import { CatalogProductRepositoryInterface } from '@/domain/product/catalogProductRepository.interface';
import { EProductStatus, Product, ProductCore } from '@/domain/product/types';
import { internationalizeIndexName } from '@/infrastructure/externalServices/algolia/internationalizeIndexName';
import { AlgoliaProduct } from '@/infrastructure/externalServices/algolia/types';

export default class CatalogProductRepository implements CatalogProductRepositoryInterface {
  readonly #client: SearchClient;
  readonly #i18nLocale: string;
  readonly #productsIndexName: string;
  readonly #querySuggestionsIndexName: string;

  constructor(
    client: SearchClient,
    i18nLocale: string,
    productsIndexName: string,
    querySuggestionsIndexName: string,
  ) {
    this.#client = client;
    this.#i18nLocale = i18nLocale;
    this.#productsIndexName = productsIndexName;
    this.#querySuggestionsIndexName = querySuggestionsIndexName;
  }

  async getProductsSuggestions(query: string): Promise<ProductCore[]> {
    const index = this.#getIndex(this.#productsIndexName);
    const filters = `${EFacetNameToggle.Status}:${EProductStatus.Published}`
     + ` AND ${EFacetNameToggle.Retail}:false`;

    const searchResults = await index.search<AlgoliaProduct>('', {
      filters,
      hitsPerPage: 6,
      query,
    });

    return searchResults?.hits?.map((item) => algoliaProductAdapter(item)) || [];
  }

  async getQuerySuggestions(query: string): Promise<QuerySuggestionDocumentSet> {
    const emptySuggestions = Object.freeze({
      documents: [],
      total: 0,
    });

    const parameters = {
      attributesToRetrieve: ['pageType', 'query', 'url'],
      hitsPerPage: 5,
      highlightPreTag: '<mark>',
      highlightPostTag: '</mark>',
    };
    const fmtIndexName = this.#getQuerySuggestionsIndexName(this.#querySuggestionsIndexName);

    const { hits, nbHits } = await this.#searchIndex(fmtIndexName, query, parameters);

    return {
      documents: hits ?? emptySuggestions.documents,
      total: nbHits ?? emptySuggestions.total,
    };
  }

  async getSimilarProducts(product: Product): Promise<ProductCore[]> {
    const index = this.#getIndex(this.#productsIndexName);
    const optionalFilters: string[] = [];
    const filters = `${EFacetNameToggle.Status}:${EProductStatus.Published}`
     + ` AND ${EFacetNameToggle.Retail}:false`
     + ` AND NOT ${EFacetName.Sku}:${product.sku}`;

    if (product?.style) {
      optionalFilters.push(`${EFacetName.StyleSlug}:${product.style.slug}`);
    }

    if (product?.color) {
      optionalFilters.push(`${EFacetName.ColorSlug}:${product.color.slug}`);
    }

    if (product?.material) {
      optionalFilters.push(`${EFacetName.MaterialSlug}:${product.material.slug}`);
    }

    const searchResults = await index.search<AlgoliaProduct>('', {
      hitsPerPage: 15,
      filters,
      facetFilters: [`${EFacetName.CategoryId}:${product?.category?.legacyId}`], // NOTE: Only legacy IDs have been indexed.
      optionalFilters,
    });

    return searchResults?.hits?.map((item) => algoliaProductAdapter(item)) || [];
  }

  #getIndex(indexName: string): SearchIndex {
    const i18nLocaleIndexName = internationalizeIndexName(indexName, this.#i18nLocale);

    return this.#client.initIndex(i18nLocaleIndexName);
  }

  #getQuerySuggestionsIndexName(indexName: string): string {
    let index = indexName;

    if (this.#i18nLocale !== ELanguage.FR) {
      index = index.replace('_enriched', '');
    }

    return index;
  }

  async #searchIndex(indexName: string, query: string, parameters?: any): Promise<Readonly<Promise<SearchResponse<any>>>> {
    const index = this.#getIndex(indexName);
    const searchResults = await index.search(query, parameters);

    return searchResults;
  }
}
