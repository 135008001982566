import { Plugin } from '@nuxt/types';

import { AuthService } from '@/domain/auth/auth.service';
import { CategoryService } from '@/domain/product/category.service';
import { ShopService } from '@/domain/shop/shop.service';
import { ServiceTree } from '@/infrastructure/core/service/ServiceTree.interface';
import AlgoliaSearchInsights from '@/infrastructure/externalServices/algolia/searchInsights';
import LaunchDarklyClient from '@/infrastructure/externalServices/launchDarkly/launchDarklyClient';
import CategoryRouter from '@/infrastructure/product/categoryRouter';
import { EditoBlocksDataService } from '@/services/data/editoBlocksData.service';
import { HeaderDataService } from '@/services/data/headerData.service';
import { NotificationDataService } from '@/services/data/notificationData.service';

const servicesPlugin: Plugin = ({ app, $accessor, $repositories, $httpCache, $config, $cookies }, inject) => {
  const services: ServiceTree = {
    algoliaSearchInsights: new AlgoliaSearchInsights(
      $config.algolia.appId,
      $config.algolia.apiKey,
      $config.algolia.indices.products,
      $accessor.user.id,
      $cookies,
      app.i18n.locale,
    ),
    authService: new AuthService($repositories.auth, $cookies),
    categoryRouter: new CategoryRouter(app.router!),
    categoryService: new CategoryService($repositories.product, app.i18n.locale),
    editoBlocksDataService: new EditoBlocksDataService($repositories.edito),
    headerDataService: new HeaderDataService($repositories.edito, app.i18n.locale),
    launchDarklyClient: new LaunchDarklyClient($httpCache, $config.launchDarkly.token, $config.appEnvironment),
    notificationDataService: new NotificationDataService($repositories.forum, $repositories.cart, $repositories.product),
    shopService: new ShopService($repositories.shop),
  };

  inject('services', services);
};

export default servicesPlugin;
