
import Vue, { PropType } from 'vue';

import IcLoader from '@/assets/svg/ic_loader.svg?inline';
import IcStarWireframe from '@/assets/svg/ic_star_wireframe.svg?inline';
import IcStarWireframeEmpty from '@/assets/svg/ic_star_wireframe_empty.svg?inline';
import { Product } from '@/domain/product/types';

// NOTE: this component should only be visible to admins. No need to make it accessible to screen readers.
export default Vue.extend({
  name: 'ButtonAddToSelectionTriage',
  components: {
    IcLoader,
    IcStarWireframe,
    IcStarWireframeEmpty,
  },
  props: {
    productId: {
      type: String as PropType<Product['id'] | Product['legacyId']>,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isProductInTriage(): boolean {
      return this.$accessor?.selection?.triageProductIds?.includes(this.productId);
    },
  },
  methods: {
    async addToTriage(): Promise<void> {
      try {
        this.isLoading = true;

        if (this.isProductInTriage) {
          await this.$repositories.selection.removeProductFromTriage(this.productId);

          this.$accessor.selection.REMOVE_PRODUCT_FROM_TRIAGE_PRODUCTS_BY_ID(this.productId);
        } else {
          const { product } = await this.$repositories.selection.addProductToTriage(this.productId);

          this.$accessor.selection.ADD_PRODUCT_TO_TRIAGE_PRODUCTS(product);
        }
      } catch (err) {
        this.$errorMonitor.report(err, 'info');
      } finally {
        this.isLoading = false;
      }
    },
  },
});
