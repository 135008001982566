
import Vue from 'vue';

import { ERouteName } from '@/domain/core/Routes.enum';
import { LinkGroup } from '@/infrastructure/core/components/link/LinkGroup.interface';

enum EFooterNavigation {
  About = 'about',
  Buying = 'buying',
  Help = 'help',
  Selling = 'selling',
}

const baseI18nPath = 'footer.navigation';
const target = '_blank';

export default Vue.extend({
  name: 'NavigationFooter',
  computed: {
    navItems(): LinkGroup[] {
      return [
        this.aboutLinks,
        this.buyingLinks,
        this.sellingLinks,
        this.helpLinks,
      ];
    },
    aboutLinks(): LinkGroup {
      const localI18n = `${baseI18nPath}.${EFooterNavigation.About}`;

      return {
        title: this.$t(`${localI18n}.title`),
        links: [
          {
            label: this.$t(`${localI18n}.links.about.label`),
            props: { href: this.$externalURLRouter.getPathFromRoute('about-us') },
          },
          {
            label: this.$t(`${localI18n}.links.green.label`),
            props: { href: this.$externalURLRouter.getPathFromRoute('our-engagements') },
          },
          {
            label: this.$t(`${localI18n}.links.press.label`),
            props: { href: this.$externalURLRouter.getPathFromRoute('press') },
          },
          {
            label: this.$t(`${localI18n}.links.hiring.label`),
            props: {
              href: this.$t(`${localI18n}.links.hiring.url`),
              target,
            },
          },
          {
            label: this.$t(`${localI18n}.links.policy.label`),
            props: {
              href: this.$t(`${localI18n}.links.policy.url`),
              target,
            },
          },
          {
            label: this.$t(`${localI18n}.links.legal.label`),
            props: {
              href: this.$t(`${localI18n}.links.legal.url`),
              target,
            },
          },
          {
            label: this.$t(`${localI18n}.links.privacy.label`),
            props: {
              href: this.$t(`${localI18n}.links.privacy.url`),
              target,
            },
          },
          {
            label: this.$t(`${localI18n}.links.cookies.label`),
            props: {
              href: this.$t(`${localI18n}.links.cookies.url`),
              target,
            },
          },
        ],
      };
    },
    buyingLinks(): LinkGroup {
      const localI18n = `${baseI18nPath}.${EFooterNavigation.Buying}`;

      return {
        title: this.$t(`${localI18n}.title`),
        links: [
          {
            label: this.$t(`${localI18n}.links.account.label`),
            props: {
              href: this.$t(`${localI18n}.links.account.url`),
              target,
            },
          },
          {
            label: this.$t(`${localI18n}.links.beforePurchase.label`),
            props: {
              href: this.$t(`${localI18n}.links.beforePurchase.url`),
              target,
            },
          },
          {
            label: this.$t(`${localI18n}.links.delivery.label`),
            props: {
              href: this.$t(`${localI18n}.links.delivery.url`),
              target,
            },
          },
          {
            label: this.$t(`${localI18n}.links.afterPurchase.label`),
            props: {
              href: this.$t(`${localI18n}.links.afterPurchase.url`),
              target,
            },
          },
          {
            label: this.$t(`${localI18n}.links.giftcard.label`),
            props: {
              href: this.$t(`${localI18n}.links.giftcard.url`),
              target,
            },
          },
        ],
      };
    },
    sellingLinks(): LinkGroup {
      const localI18n = `${baseI18nPath}.${EFooterNavigation.Selling}`;

      return {
        title: this.$t(`${localI18n}.title`),
        links: [
          {
            label: this.$t(`${localI18n}.links.howTo.label`),
            props: { href: this.$externalURLRouter.getPathFromRoute('how-to-sell') },
          },
          {
            label: this.$t(`${localI18n}.links.myAds.label`),
            props: {
              href: this.$t(`${localI18n}.links.myAds.url`),
              target,
            },
          },
          {
            label: this.$t(`${localI18n}.links.delivery.label`),
            props: {
              href: this.$t(`${localI18n}.links.delivery.url`),
              target,
            },
          },
          {
            label: this.$t(`${localI18n}.links.payment.label`),
            props: {
              href: this.$t(`${localI18n}.links.payment.url`),
              target,
            },
          },
        ],
      };
    },
    helpLinks(): LinkGroup {
      const localI18n = `${baseI18nPath}.${EFooterNavigation.Help}`;

      return {
        title: this.$t(`${localI18n}.title`),
        links: [
          {
            label: this.$t(`${localI18n}.links.contact.label`),
            props: { href: this.$router.resolve({ name: ERouteName.Contact }).href },
          },
          {
            label: this.$t(`${localI18n}.links.pro.label`),
            props: { href: this.$externalURLRouter.getPathFromRoute('pro-space') },
          },
          {
            label: this.$t(`${localI18n}.links.helpBuyer.label`),
            props: {
              href: this.$t(`${localI18n}.links.helpBuyer.url`),
              target,
            },
          },
          {
            label: this.$t(`${localI18n}.links.helpSeller.label`),
            props: {
              href: this.$t(`${localI18n}.links.helpSeller.url`),
              target,
            },
          },
        ],
      };
    },
  },
});
