
import Vue from 'vue';

const buttonDisplayTimeInMs = 5000;

export default Vue.extend({
  name: 'WishlistBoardsDropdownOpenerButton',
  data() {
    return {
      isMouseOver: false,
    };
  },
  mounted() {
    this.handleButtonDisplay();
  },
  methods: {
    handleClick(): void {
      this.$emit('click');
    },
    handleButtonDisplay(): void {
      setTimeout(() => {
        if (this.isMouseOver) {
          this.handleButtonDisplay();
        } else {
          this.$emit('hide');
        }
      }, buttonDisplayTimeInMs);
    },
    setMouseOverState(state: boolean): void {
      this.isMouseOver = state;
    },
  },
});
