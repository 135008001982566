import { CmsRepositoryInterface } from '@/domain/cms/cmsRepository.interface';
import { EStaticAsset, HandmadeCategory, MenuItem, SearchQuery } from '@/domain/cms/types';
import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { transformList } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

const domainPath = '/cms';
const staticAssetPath = '/static-asset';

export default class CmsRepository implements CmsRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;

  constructor(
    httpService: HttpService,
    basePath: string,
  ) {
    this.#basePath = basePath;
    this.#httpService = httpService;
  }

  async getHandmadeCategories(): Promise<HandmadeCategory[]> {
    const { content } = await this.#httpService.request<ApiBase<HandmadeCategory>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${domainPath}${staticAssetPath}/${EStaticAsset.HandmadeCategories}`,
    });

    return transformList(content?.items);
  }

  async getMenu(): Promise<MenuItem[]> {
    const { content } = await this.#httpService.request<ApiBase<MenuItem>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${domainPath}/menu/current`,
    });

    return transformList(content?.items);
  }

  async getPopularSearchQueries(): Promise<SearchQuery[]> {
    const { content } = await this.#httpService.request<ApiBase<SearchQuery>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${domainPath}${staticAssetPath}/${EStaticAsset.PopularSearches}`,
    });

    return transformList(content?.items);
  }
}
