import { ScriptHTMLAttributes } from 'vue/types/jsx';

export const _insertScript = (attributes: ScriptHTMLAttributes, isAppendedToHead = false): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`#${attributes?.id}`)) {
      resolve();

      return;
    }

    const scriptTag = document.createElement('script');

    for (const property in attributes) {
      scriptTag.setAttribute(property, attributes[property as keyof ScriptHTMLAttributes]);
    }

    scriptTag.addEventListener('load', () => resolve());
    scriptTag.addEventListener('error', (event) => {
      scriptTag.remove(); // Remove script tag as its "falsy" presence would prevent the browser from re-downloading it properly.

      reject(event);
    });

    if (isAppendedToHead) {
      const [head] = document.querySelectorAll('head');

      head.append(scriptTag);
    } else {
      document.body.append(scriptTag);
    }
  });
};
