import { ErrorMonitorInterface } from '@/services/errorMonitor.service';
import { ExternalURLRouterInterface } from '@/services/interfaces/externalURLRouter.interface';

export default class ExternalURLRouter implements ExternalURLRouterInterface {
  readonly #errorMonitor: ErrorMonitorInterface;
  readonly #routes: Record<string, string>;

  constructor(routes: Record<string, string>, errorMonitor: ErrorMonitorInterface) {
    this.#errorMonitor = errorMonitor;
    this.#routes = routes;
  }

  getPathFromRoute = (route: string, replacementTexts: Record<string, string> | null = null): string | null => {
    let path = this.#routes?.[route];

    if (!path) {
      this.#errorMonitor.report(`No path found for route "${route}"`, 'error');

      return null;
    }

    for (const stringToReplace in replacementTexts) {
      path = path.replace(`{${stringToReplace}}`, replacementTexts[stringToReplace]);
    }

    return path;
  };
}

export class MockExternalURLRouter implements ExternalURLRouterInterface {
  getPathFromRoute = (route: string, replacementTexts: Record<string, string> | null = null): string | null => {
    for (const stringToReplace in replacementTexts) {
      route = route.replace(`{${stringToReplace}}`, replacementTexts[stringToReplace]);
    }

    return route;
  };
}
