
import Vue from 'vue';

export default Vue.extend({
  name: 'TransitionCollapse',
  functional: true,
  render(createElement, context) {
    const data = {
      props: {
        name: 'collapse',
      },
      on: {
        afterEnter(element) {
          element.style.height = 'auto';
        },
        enter(element) {
          // setTimeout is necessary when combined with a v-if in the template:
          // Wait until DOM is rendered before computing styles
          setTimeout(() => {
            const { width } = getComputedStyle(element);

            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';

            const { height } = getComputedStyle(element);

            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;

            // eslint-disable-next-line no-unused-expressions
            getComputedStyle(element).height;

            requestAnimationFrame(() => {
              element.style.height = height;
            });
          }, 0);
        },
        leave(element) {
          const height = getComputedStyle(element).height;

          element.style.height = height;

          // eslint-disable-next-line no-unused-expressions
          getComputedStyle(element).height;

          requestAnimationFrame(() => {
            element.style.height = 0;
          });
        },
      },
    };

    return createElement('transition', data, context.children);
  },
});
