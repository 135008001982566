
import Vue, { PropType } from 'vue';

import IcChevronRight from '@/assets/svg/ic_chevron_right.svg?inline';
import { defaultCarouselOptions } from '@/config/plugins/SsrCarouselOptions';
import { BaseCardContent } from '@/infrastructure/core/components/card/BaseCard.interface';
import { BaseCarouselOptions } from '@/infrastructure/core/components/carousel/BaseCarousel.interface';

type Slide = BaseCardContent;

export default Vue.extend({
  name: 'BaseCarousel',
  components: {
    IcChevronRight,
  },
  props: {
    slides: {
      type: Array as PropType<Slide[]>,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    customCarouselOptions: {
      type: Object as PropType<BaseCarouselOptions>,
      default: (): BaseCarouselOptions => ({}),
    },
  },
  computed: {
    carouselOptions(): BaseCarouselOptions {
      return { ...defaultCarouselOptions, ...this.customCarouselOptions };
    },
    page: {
      get(): number {
        return this.currentPage;
      },
      set(value: boolean): void {
        this.$emit('update:currentPage', value);
      },
    },
  },
});
