export enum ENodeEnvironment {
  Development = 'development',
  Production = 'production',
}

export enum EAppEnvironment {
  Development = 'dev',
  Production = 'prod',
  Staging = 'staging',
}
