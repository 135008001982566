import { render, staticRenderFns } from "./BaseCarousel.vue?vue&type=template&id=7d7ecb58"
import script from "./BaseCarousel.vue?vue&type=script&lang=ts"
export * from "./BaseCarousel.vue?vue&type=script&lang=ts"
import style0 from "./BaseCarousel.vue?vue&type=style&index=0&id=7d7ecb58&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SsrCarousel: require('/home/runner/work/webapp/webapp/node_modules/vue-ssr-carousel/index.js').default})
