import { render, staticRenderFns } from "./SearchList.vue?vue&type=template&id=b4a0cd4c"
import script from "./SearchList.vue?vue&type=script&lang=ts"
export * from "./SearchList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PlaceholderSearchList: require('/home/runner/work/webapp/webapp/components/organisms/placeholder/PlaceholderSearchList.vue').default,SearchSaved: require('/home/runner/work/webapp/webapp/components/molecules/search/SearchSaved.vue').default,BaseLink: require('/home/runner/work/webapp/webapp/components/atoms/link/BaseLink.vue').default})
