
import Vue from 'vue';

import { ELocalStorageItem } from '@/domain/core/LocalStorageItem.enum';
import { ERouteName } from '@/domain/core/Routes.enum';
import { EGtmEventSearch } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { SearchEvent } from '@/infrastructure/externalServices/gtm/search/SearchEventPayload.interface';

export default Vue.extend({
  name: 'SearchListRecent',
  data() {
    return {
      recentSearchList: [] as string[],
    };
  },
  mounted() {
    this.fetchRecentSearchList();
  },
  methods: {
    fetchRecentSearchList(): void {
      this.recentSearchList = JSON.parse(localStorage.getItem(ELocalStorageItem.SearchListRecent) || '[]');
    },
    getSearchQueryHref(query: string): string {
      return this.$router.resolve({
        name: ERouteName.Search,
        query: { query },
      }).href;
    },
    handleClick(query: string): void {
      this.$emit('click-suggestion', query);
      this.handleTracking(query);
    },
    handleTracking(query: string): void {
      const payload: SearchEvent = {
        search_term: query,
        url: `${window.location.origin}${this.getSearchQueryHref(query)}`,
      };

      this.$gtm.push({
        event: EGtmEventSearch.ClickRecent,
        ...payload,
      });
    },
  },
});
