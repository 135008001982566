import { render, staticRenderFns } from "./PlaceholderSearchList.vue?vue&type=template&id=726885ef"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PlaceholderSearchSaved: require('/home/runner/work/webapp/webapp/components/atoms/placeholder/PlaceholderSearchSaved.vue').default})
