import { render, staticRenderFns } from "./ProductPrice.vue?vue&type=template&id=68cd26e8"
import script from "./ProductPrice.vue?vue&type=script&lang=ts"
export * from "./ProductPrice.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductPriceTag: require('/home/runner/work/webapp/webapp/components/atoms/product/ProductPriceTag.vue').default,BadgeProPrice: require('/home/runner/work/webapp/webapp/components/atoms/badge/BadgeProPrice.vue').default,BadgeHandmade: require('/home/runner/work/webapp/webapp/components/atoms/badge/BadgeHandmade.vue').default,BadgeNegotiable: require('/home/runner/work/webapp/webapp/components/atoms/badge/BadgeNegotiable.vue').default})
