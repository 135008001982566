export interface AlgoliaHighlightResult {
  fullyHighlighted?: boolean;
  matchLevel?: string;
  matchedWords?: string[];
  value: string;
}
export interface AlgoliaHitHighlightResult {
  query: AlgoliaHighlightResult;
}

export interface DocumentSet<T> {
  documents: T;
  total: number;
}

export interface GeolocationQuerySuggestionDocument {
  _highlightResult: {
    admin_code1: AlgoliaHighlightResult;
    admin_code2: AlgoliaHighlightResult;
    admin_code3: AlgoliaHighlightResult;
    place_name: AlgoliaHighlightResult;
    postal_code: AlgoliaHighlightResult;
  };
  country_code: string;
  objectID: string;
  place_name: string;
  postal_code: string;
}

export type GeolocationQuerySuggestionDocumentSet = DocumentSet<GeolocationQuerySuggestionDocument[]>;

export interface QuerySuggestionDocument {
  _highlightResult: AlgoliaHitHighlightResult;
  objectID: string;
  pageType: string;
  query: string;
  url: string;
}

export type QuerySuggestionDocumentSet = DocumentSet<QuerySuggestionDocument[]>;

export enum EFacetNameRefinementList {
  CategoryId = 'categoryId',
  CategoryLevel1 = 'categoryLvl1Title',
  CategoryLevel2 = 'categoryLvl2Title',
  CategoryLevel3 = 'categoryLvl3Title',
  ColorFacet = 'colorFacet',
  ColorSlug = 'colorSlug',
  Country = 'country',
  Department = 'departmentFacet',
  DesignerTitle = 'designerTitle',
  IsHandmade = 'isHandmade',
  MaterialSlug = 'materialSlug',
  MaterialTitle = 'materialTitle',
  QualityMark = 'qualityMark',
  Quantity = 'batchQuantity',
  Region = 'region',
  Sku = 'sku',
  StyleSlug = 'styleSlug',
  StyleTitle = 'styleTitle',
}

export enum EFacetNameToggleRefinement {
  IsDeliveryBySelency = 'isDeliveryBySelency',
  IsDeliveryBySeller = 'isDeliveryBySeller',
  IsDeliveryCocolis = 'isDeliveryCocolis',
  IsDeliveryColissimo = 'isDeliveryColissimo',
  IsDeliveryCustom = 'isDeliveryCustom',
  IsDiscounted = 'isDiscounted',
  IsNegotiable = 'isNegotiable',
  IsProPrice = 'isProPrice',
  IsSellerPro = 'isSellerPro',
  IsTradeProgram = 'isTradeProgram',
  Retail = 'retail',
  Status = 'status',
}

export enum EFacetNameRange {
  CurrentPrice = 'currentPrice',
  Depth = 'depth',
  Height = 'height',
  Width = 'width',
}

export enum EFacetNameNumericMenu {
  Discount = 'discount',
  PublishedAt = 'publishedAt',
  SellerNote = 'sellerNote',
}

export enum EFacetBusinessScore {
  Default = 'business_score_abtest',
  Pro = 'business_score_pro_abtest',
}
export enum ESearchInsightEvent {
  AddToCart = 'add_to_cart',
  AddToWishlist = 'add_to_wishlist',
  ContactTheSeller = 'contact_the_seller_from_product_page',
  Negotiate = 'negotiate_from_product_page',
  OpenProductPage = 'open_product_page',
}

export enum ESearchInsightEventType {
  Click = 'clickedObjectIDsAfterSearch',
  Conversion = 'convertedObjectIDsAfterSearch',
}

export enum EHitsThreshold {
  Max = 60,
  Min = 9,
}
