
import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';

import { Product } from '@/domain/product/types';
import { WishlistBoard } from '@/domain/wishlist/types';

const modalCloseTimeInMs = 2000;

export default Vue.extend({
  name: 'FormWishlistBoard',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  data() {
    return {
      errorMsg: '' as TranslateResult,
      isLoading: false,
      newBoardName: '',
    };
  },
  computed: {
    shouldDisableSubmitButton(): boolean {
      return this.isLoading || this.newBoardName.length === 0 || this.isBoardCreated;
    },
    boards(): WishlistBoard[] {
      return this.$accessor.wishlist.boards;
    },
    isBoardCreated(): boolean {
      return this.boards.some(({ name }) => name === this.newBoardName);
    },
  },
  methods: {
    async createWishlistBoard(): Promise<void> {
      this.isLoading = true;

      try {
        await this.$accessor.wishlist.createBoard(this.newBoardName);

        const { id: boardId } = this.boards.find(({ name }) => name === this.newBoardName) || {};

        await this.$accessor.wishlist.addProduct({
          boardId,
          productId: this.product.id,
        });
      } catch (err) {
        this.errorMsg = this.$t('form.common.errors.generic');
        this.$errorMonitor.report(err, 'error');
      } finally {
        this.isLoading = false;
      }

      setTimeout(() => {
        this.close();
      }, modalCloseTimeInMs);
    },
    close(): void {
      this.$emit('close');
    },
  },
});
