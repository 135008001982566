import { actionTree, mutationTree } from 'typed-vuex';

import { EHeader as MutationType } from '@/domain/core/store/MutationType.enum';
import { MainMenuItem } from '@/infrastructure/core/components/navigation/MainMenuItem.interface';
import { HttpResponse } from '@/infrastructure/core/http/HttpResponse.interface';
import { HeaderState } from '@/infrastructure/core/store/modules/Header.interface';
import { accessorType } from '@/store';

export const state = (): HeaderState => ({
  navigation: [],
  headerBannerContent: null,
});

export const mutations = mutationTree(state, {
  [MutationType.SET_NAVIGATION]: (state, payload: MainMenuItem[]): void => {
    state.navigation = payload;
  },
  [MutationType.SET_HEADER_BANNER]: (state, payload: string): void => {
    state.headerBannerContent = payload;
  },
});

export const actions = actionTree({ state, mutations }, {
  async fetchHeaderData(): Promise<void> {
    try {
      const accessor: typeof accessorType = this.app.$accessor;
      const { accentColor, menu, bannerTop } = await this.$services.headerDataService.getHeaderData();

      accessor.ui.setAccentColor(accentColor);
      accessor.header.SET_NAVIGATION(menu);
      accessor.header.SET_HEADER_BANNER(bannerTop?.content);
    } catch (err) {
      this.app.$errorMonitor.report((err as HttpResponse)?.content || err, 'fatal');
    }
  },
});
