import { render, staticRenderFns } from "./InstantSearchSuggestions.vue?vue&type=template&id=3c67bdae&scoped=true"
import script from "./InstantSearchSuggestions.vue?vue&type=script&lang=ts"
export * from "./InstantSearchSuggestions.vue?vue&type=script&lang=ts"
import style0 from "./InstantSearchSuggestions.vue?vue&type=style&index=0&id=3c67bdae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c67bdae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLink: require('/home/runner/work/webapp/webapp/components/atoms/link/BaseLink.vue').default})
