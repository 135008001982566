
import Vue, { VueConstructor } from 'vue';
import { NumberFormatOptions } from 'vue-i18n';

import currency from '@/mixins/currency';
import { _applyCurrencyRate } from '@/utilities/pricing/applyCurrencyRate';

export default (Vue as VueConstructor<Vue & InstanceType<typeof currency>>).extend({
  name: 'ProductPriceTag',
  mixins: [currency],
  props: {
    hasFractionDigits: {
      type: Boolean,
      default: false,
    },
    isOldPrice: {
      type: Boolean,
      default: false,
    },
    isSoldOut: {
      type: Boolean,
      default: false,
    },
    isUnstyled: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Number,
      required: true,
    },
    shouldEnableMicrodata: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentType(): keyof HTMLElementTagNameMap {
      return this.isOldPrice ? 'del' : 'span';
    },
    localFormatOptions(): NumberFormatOptions {
      const options = { ...this.$_currency_i18nNumberFormatOptions };

      if (!this.hasFractionDigits) {
        options.minimumFractionDigits = 0;
        options.maximumFractionDigits = 0;
      }

      return options;
    },
    priceWithCurrencyRateApplied(): number {
      return _applyCurrencyRate(this.price, this.$accessor.pricing.getSelectedCurrencyRate);
    },
    priceWithCurrencyRateAppliedInParts(): Intl.NumberFormatPart[] {
      return this.$_currency_numberFormatToParts(this.priceWithCurrencyRateApplied, this.localFormatOptions);
    },
    productPriceDataTestId(): string {
      return `product-${this.isOldPrice ? 'old-price' : 'price'}-value`;
    },
  },
});
