import { ShopUpdatePayload } from '@/domain/auth/types';
import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { ApiItemBase } from '@/domain/core/api/ApiItemBase.interface';
import { EEmbedsName, Embeds } from '@/domain/core/api/embeds.interface';
import { PaginatedList } from '@/domain/core/api/types';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import { Review } from '@/domain/review/types';
import { ShopRepositoryInterface } from '@/domain/shop/shopRepository.interface';
import { ShopDetails, ShopStats } from '@/domain/shop/types';
import { UserPublic } from '@/domain/user/types';
import { transform, transformListPaginated } from '@/infrastructure/core/apiData.transformer';
import HttpService from '@/services/http.service';

const shopResourcePath = '/shops';
const statsResourcePath = '/statistics';

export default class ShopRepository implements ShopRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;
  readonly #security: SecurityTokenProvider;

  constructor(httpService: HttpService, basePath: string, security: SecurityTokenProvider) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#security = security;
  }

  async getShopByUserId(userId: UserPublic['id']): Promise<ShopDetails> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiItemBase<ShopDetails>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}/users/${userId}/shop`,
    });

    return transform(content);
  }

  async getShopDetails(id: string): Promise<ShopDetails & { statistics: ShopStats; user: UserPublic }> {
    const { content } = await this.#httpService.request<ApiItemBase<ShopDetails, Pick<Embeds, EEmbedsName.Statistics | EEmbedsName.User>>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${shopResourcePath}/${id}`,
      queryParams: {
        embeds: [EEmbedsName.Statistics, EEmbedsName.User].join(','),
      },
    });

    return transform(content) as ShopDetails & { statistics: ShopStats; user: UserPublic };
  }

  async getShopReviews(id: string, page: number | null = null, limit = 50): Promise<PaginatedList<Review>> {
    const { content } = await this.#httpService.request<ApiBase<Review>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${shopResourcePath}/${id}/order-entry-reviews`,
      queryParams: {
        embeds: EEmbedsName.Buyer,
        limit,
        page,
      },
    });

    return transformListPaginated(content);
  }

  async getShopStats(id: string): Promise<ShopStats> {
    const token = this.#security.getToken();

    const content = await this.#httpService.request<ApiItemBase<ShopStats>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${shopResourcePath}/${id}${statsResourcePath}`,
    });

    return transform(content.content);
  }

  async updateShop(id: ShopDetails['id'], payload: ShopUpdatePayload): Promise<ShopDetails> {
    const token = this.#security.getToken();

    const { content } = await this.#httpService.request<ApiItemBase<ShopDetails>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Patch,
      path: `${this.#basePath}/shops/${id}`,
      payload,
    });

    return transform(content);
  }
}
