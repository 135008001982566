
import Vue from 'vue';

import IcLoader from '@/assets/svg/ic_loader.svg?inline';
import IcPlus from '@/assets/svg/ic_plus.svg?inline';

export default Vue.extend({
  name: 'WishlistBoardsDropdownOpener',
  components: {
    IcLoader,
    IcPlus,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isProductInWishlist: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(): void {
      this.$emit('click');
    },
  },
});
