
import Vue from 'vue';

import { EKeyboardEventKey } from '@/domain/core/KeyboardEvent.enum';

export default Vue.extend({
  name: 'DialogBackdrop',
  data() {
    return {
      body: {} as HTMLElement,
      bodyPosition: {
        left: 0,
        top: 0,
      },
      doc: {} as Element,
      isFixed: false,
    };
  },
  mounted() {
    this.body = document.body;
    this.doc = document.documentElement;

    document.addEventListener('keyup', this.handleEscapeKeyPress);

    this.fixDocument();
  },
  destroyed() {
    this.unFixDocument();
    document.removeEventListener('keyup', this.handleEscapeKeyPress);
  },
  methods: {
    closeDialog(): void {
      this.$accessor.ui.closeDialog();
    },
    handleEscapeKeyPress(event: KeyboardEvent): void {
      if (event.key === EKeyboardEventKey.Escape) {
        this.closeDialog();
      }
    },
    fixDocument(): void {
      if (this.isFixed) {
        return;
      }

      this.bodyPosition = {
        left: (window.pageXOffset || this.doc.scrollLeft) - (this.doc.clientLeft || 0),
        top: (window.pageYOffset || this.doc.scrollTop) - (this.doc.clientTop || 0),
      };

      this.body.style.position = 'fixed';
      this.body.style.left = '0';
      this.body.style.top = `${-this.bodyPosition.top}px`;
      this.isFixed = true;
    },
    unFixDocument(): void {
      this.body.style.top = '0';
      this.body.style.position = 'relative';
      this.isFixed = false;

      window.scrollTo(0, this.bodyPosition.top);
    },
    preventDefault(event: Event): void {
      const event_ = event || window.event;

      if (event_.preventDefault) {
        event_.preventDefault();
      }
    },
  },
});
