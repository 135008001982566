import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { CurrencyRepositoryInterface } from '@/domain/pricing/currencyRepository.interface';
import { CurrencyRate } from '@/domain/pricing/types';
import { transformList } from '@/infrastructure/core/apiData.transformer';
import { HttpCacheClientInterface } from '@/infrastructure/core/http/httpCacheClient';

export default class CurrencyRepository implements CurrencyRepositoryInterface {
  readonly #basePath: string;
  readonly #httpCache: HttpCacheClientInterface;
  readonly #currencyRatesCacheKey = 'currency-rates';

  constructor(httpCache: HttpCacheClientInterface, basePath: string) {
    this.#basePath = basePath;
    this.#httpCache = httpCache;
  }

  async getCurrencyRates(): Promise<CurrencyRate[]> {
    const { content } = await this.#httpCache.getCachedResponse<ApiBase<CurrencyRate>>(
      this.#currencyRatesCacheKey,
      {
        headers: {
          [EHeader.Accept]: EApiVersion.V2,
        },
        method: EHttpMethod.Get,
        path: `${this.#basePath}/currency-rates`,
      },
      {
        EX: 86_400, // One day
      },
    );

    return transformList(content?.items);
  }
}
