
import Vue, { VueConstructor } from 'vue';

import { ENumberFormatPart } from '@/domain/core/NumberFormatPart.enum';
import { BaseSelectOption } from '@/infrastructure/core/components/input/BaseSelectOption.interface';
import currency from '@/mixins/currency';

export default (Vue as VueConstructor<Vue & InstanceType<typeof currency>>).extend({
  name: 'SelectCurrency',
  mixins: [currency],
  computed: {
    currencyCodes(): BaseSelectOption[] {
      return this.$accessor.pricing.getCurrencyCodes.map((code) => ({ label: code, value: code }));
    },
    selectedCurrencySymbol(): string {
      const arbitraryAmount = 1;
      const currencyPart = this.$_currency_numberFormatToParts(arbitraryAmount)
        .find(({ type }) => type === ENumberFormatPart.Currency);

      return currencyPart?.value || '';
    },
  },
});
