var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Transition',{attrs:{"mode":_vm.transitionMode,"name":_vm.transitionName}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"absolute z-10 transition-all",class:{
      'inset-x-0 transform p-10 min-h-[380px] w-full left-0 xl:max-w-6xl bg-white xl:left-[calc((100vw-72rem)/2)]': _vm.level < 2,
      'top-0 px-10 pb-4 left-full w-48': _vm.level >= 2,
    },style:(_vm.computedStyle)},[_c('div',{staticClass:"container relative z-10 flex justify-between mx-auto"},[_c('div',{class:{
          'w-1/2': _vm.level === 1,
        }},[(_vm.seeAll && _vm.seeAll.name)?_c('BaseLink',{staticClass:"mb-6 uppercase text-tiny",attrs:{"data-testid":`link-category-see-all-${_vm.seeAll.url}`,"href":_vm.seeAll.url},on:{"click":function($event){return _vm.trackSubmenuClick(_vm.EGtmEventHeader.ClickLinkMainNav, { name: _vm.seeAll.name, url: _vm.seeAll.url })}}},[_vm._v("\n          "+_vm._s(_vm.$t('header.mainNavigation.seeAll', { category: _vm.seeAll.name }))+"\n        ")]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"relative",class:{
            'w-48': _vm.level < 2,
            'w-72': _vm.level >= 2,
          },on:{"mouseleave":_vm.handleMouseLeave}},_vm._l((_vm.submenu),function({ name, slug, url, child, isNew, isPromo },index){return _c('li',{key:`${slug}_${index}`,staticClass:"submenuItem",class:{
              'hover:underline': url,
            },on:{"mouseover":function($event){return _vm.handleHover(index, $event)}}},[(url)?_c('NavigationMainLink',{attrs:{"is-new":isNew,"is-promo":isPromo,"level":_vm.level,"url":url,"value":name},on:{"focus":function($event){return _vm.handleFocus(index, $event)}}}):_c('span',[_vm._v("\n              "+_vm._s(name)+"\n            ")]),_vm._v(" "),(child && child.length > 0)?_c('NavigationMainSubmenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndex === index),expression:"activeIndex === index"}],attrs:{"is-open":_vm.activeIndex === index,"level":2,"submenu":child}}):_vm._e()],1)}),0)],1),_vm._v(" "),(_vm.level === 1)?_c('div',{staticClass:"flex justify-end w-1/2 gap-4"},_vm._l((_vm.promoBlocks),function({ title, picture, link },index){return _c('BaseLink',{key:`${picture}_${index}`,attrs:{"data-testid":`promo-block-${_vm.seeAll.url}`,"href":link,"is-unstyled":"","title":title},on:{"click":function($event){return _vm.trackSubmenuClick(_vm.EGtmEventHeader.ClickPromoMainNav, { name: title || _vm.seeAll.name, url: link })}}},[_c('NuxtImg',{staticClass:"max-w-[240px]",attrs:{"alt":title || _vm.seeAll.name,"loading":"lazy","sizes":"lg:240px 2xl:480px","src":picture || _vm.imagePlaceholderId}})],1)}),1):_vm._e()]),_vm._v(" "),_c('Transition',{attrs:{"name":"fade"}},[(_vm.level < 2)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"absolute inset-0 z-0 shadow-lg",attrs:{"aria-hidden":"true"}}):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }