import { Image } from '@/domain/core/image/types';
import { Category } from '@/domain/product/types';
import { Selection } from '@/domain/selection/types';

export enum EStaticAsset {
  HandmadeCategories = 'handmade-categories',
  PopularSearches = 'popular-searches',
}

export interface HandmadeCategory {
  imageId: string;
  title: string;
  url: string;
}

export interface SearchQuery {
  imageId: string;
  query: string;
}

interface MerchandisingBlock {
  createdAt: string;
  id: string;
  image: Image;
  title: string;
  updatedAt: string;
  url: string;
}

interface MenuItemBase {
  createdAt: string;
  id: string;
  image: Image;
  isNew: boolean;
  isPromotional: boolean;
  merchandisingBlocks: MerchandisingBlock[];
  parentId: string | null;
  title: string;
  updatedAt: string;
}

interface MenuItemSelection extends MenuItemBase {
  selection: Selection; // Embed
  selectionId: Selection['id'] | null;
}

interface MenuItemCategory extends MenuItemBase {
  category: Category; // Embed
  categoryId: Category['id'];
}

interface MenuItemCustom extends MenuItemBase {
  reference: string;
}

export type MenuItem = MenuItemBase
  & (MenuItemSelection | MenuItemCategory | MenuItemCustom)
  & { children?: MenuItem };

// NOTE: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export const isMenuItem = {
  selection: (item: MenuItem): item is MenuItemSelection => (item as MenuItemSelection)?.selection !== undefined,
  category: (item: MenuItem): item is MenuItemCategory => (item as MenuItemCategory)?.category !== undefined,
  custom: (item: MenuItem): item is MenuItemCustom => (item as MenuItemCustom)?.reference !== undefined,
} as const;
